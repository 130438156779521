export default [
  { heading: "My Account" },
  {
    title: "Profile",
    to: { name: "user-id", params: { id: "unknown" } },
    icon: { icon: "mdi-account-outline" },
    disable: false,
  },
  {
    title: "Bookings",
    to: { name: "bookings" },
    icon: { icon: "mdi-calendar-select" },
    disable: false,
  },
  {
    title: "Calendar",
    to: { name: "calendar" },
    icon: { icon: "mdi-calendar-month-outline" },
    disable: false,
  },
  {
    title: "WebShop",
    to: { name: "webshop" },
    icon: { icon: "mdi-shopping" },
    disable: false,
  },
  {
    title: "Membership",
    to: { name: "user-membership" },
    icon: { icon: "mdi-account-heart-outline" },
    disable: true,
  },

  {
    title: "FAQ",
    to: { name: "faqs-user" },
    icon: { icon: "mdi-frequently-asked-questions" },
    disable: true,
  },
  {
    title: "Log out",
    to: { name: "logout" },
    icon: { icon: "mdi-logout" },
    disable: false,
  },

  // un-authenticated users
  {
    title: "WebShop",
    to: { name: "webshop" },
    icon: { icon: "mdi-shopping" },
    disable: false,
    isAuthRequired: false,
  },
  {
    title: "Membership",
    to: { name: "user-membership" },
    icon: { icon: "mdi-account-heart-outline" },
    disable: false,
    isAuthRequired: false,
  },
  {
    title: "FAQ",
    to: { name: "faqs-user" },
    icon: { icon: "mdi-frequently-asked-questions" },
    disable: false,
    isAuthRequired: false,
  },
  {
    title: "Sign in",
    to: { name: "login" },
    icon: { icon: "mdi-login" },
    disable: false,
    isAuthRequired: false,
  },
  {
    title: "Sign up",
    to: { name: "register" },
    icon: { icon: "mdi-account-plus" },
    disable: false,
    isAuthRequired: false,
  },
];
