import { DocketRecordModel } from "@/utils/PocketBaseAdapter";
import { pb } from "@/utils/PocketBaseAdapter";
import { useDocketStore } from "@/utils/DocketStore"; // Importing another store
import isEmpty from "lodash/isEmpty";

import { defineStore } from "pinia";

export const useCheckoutStore = defineStore("CheckoutStore", {
  state: () => ({
    payingNow: 0,
    balanceToPay: 0,
    remainingBalance: 0, // this will use in split tab to store remaining balance after split amount deduct
    amtTender: 0,
    activeTab: 0,
    splitBy: 1,
    customeSplitValue: 0,
    intialSplit: 0,
    showSpinner: true,
    docketLinesItems: [],
    processingItems: [],
    originalDocketItems: [],
    paymentStatus: null,
    selectedItem: null,
    selectedProcessingItem: null,
    availableItems: [],
    discount: 0,
    lineTotals: 0,
    dockets: [] as DocketRecordModel[],
    activePaymentTab: 0,
    partialPayingAmount: 0,
    paymentStatusForSplit: "",
    isFullScreenLoading: false,
    docketNumber: "",
    docketDescription: "",
    docketLinesTotal: 0,
    docketPaymentId: "", // payment id after initial payment process
    fobDetails: {
      isVisible: false,
      userId: null,
      userName: "",
      membership: "",
      productDiscount: 0,
      bookingDiscount: 0,
      eventDiscount: 0,
    },
    loading: false,
    discountSources: [],
    MARK_AS_PAID: {
      isVisible: false,
      message: "",
      onClick: () => { },
    },
  }),
  actions: {
    // Function to handle Fob discount
    async handleFobDiscount(userId: string) {
      const docketStore = useDocketStore(); // Initialize the docket store inside the function

      try {
        // Check if userId exists in fobDetails
        if (!userId) {
          this.fobDetails = {
            isVisible: false,
            userId: null,
            userName: "",
            membership: "",
            productDiscount: 0,
            bookingDiscount: 0,
            eventDiscount: 0,
          };
          return;
        }

        // Fetch user membership data
        const userData = await pb.collection("users").getOne(userId, {
          fields: "id,membership,expand,firstName,lastName,username",
          expand: "membership",
        });

        const { id, firstName, lastName, username } = userData;
        this.fobDetails.userName = `${firstName} ${lastName}` || username;
        this.fobDetails.userId = id;
        // Update fobDetails if membership data is available
        if (userData && !isEmpty(userData?.expand?.membership)) {
          const {
            id,
            discountProduct,
            discountFurtherBookings,
            discountEvents,
          } = userData?.expand?.membership;

          this.fobDetails.membership = id;
          this.fobDetails.productDiscount = discountProduct || 0;
          this.fobDetails.bookingDiscount = discountFurtherBookings || 0;
          this.fobDetails.eventDiscount = discountEvents || 0;
        } else {
          this.fobDetails.membership = "";
          this.fobDetails.productDiscount = 0;
          this.fobDetails.bookingDiscount = 0;
          this.fobDetails.eventDiscount = 0;
        }
      } catch (error) {
        console.error("Error fetching fob discount details:", error);
      } finally {
        // Use docketStore to update the docketDiscountData
        docketStore.docketDiscountData = {
          bookingDiscount: this.fobDetails.bookingDiscount,
          productDiscount: this.fobDetails.productDiscount,
          eventDiscount: this.fobDetails.eventDiscount,
        };
      }
    },

    async subscribeUnSubscribeAccessReaders(type: string) {
      try {
        if (type === "subscribe") {
          const apps = JSON.parse(localStorage.getItem("Apps") || "{}");
          const { accessReader } = apps?.POS;
          pb.collection("accessReaders").subscribe("*", async (e) => {
            if (e && e?.record && accessReader?.includes(e?.record?.id)) {
              const { lastUser } = e?.record;
              this.fobDetails.userId = lastUser;
              await this.handleFobDiscount(lastUser);
            }
          });
        } else {
          pb.collection("accessReaders").unsubscribe("*");
        }
      } catch (e) {
        console.log(e);
      }
    },

    async checkDocketDues() {
      try {
        if (this.balanceToPay <= 0) {
          this.MARK_AS_PAID = {
            isVisible: true,
            message:
              "Docket now has a 0 balance do you want to mark it as paid?",
            onClick: async (did: string = "") => {
              await this.markDocketAsPaid(did);
            },
          };
        }
      } catch (e) { }
    },

    async markDocketAsPaid(did: string) {
      try {
        if (!did) {
          return;
        }

        await pb.collection("dockets").update(did, {
          due: 0,
          status: "Paid",
        });

        this.MARK_AS_PAID = {
          isVisible: false,
          message: "",
          onClick: () => { },
        };
      } catch (e) { }
    },
  },
  getters: {
    getBookingRecords(state) {
      return (
        state?.originalDocketItems?.filter((item: any) => item?.booking) || []
      );
    },
  },
  persist: true,
});
