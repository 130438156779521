<script setup lang="ts">
import { usePaymentStore } from "@/components/payment/usePaymentStore";
import { useBooking as useBookingStore } from "@/store/useBooking";
import { useDiscountCode } from "@/store/useDiscountCode";
import { useGlobalErrorStore } from "@/store/useGlobalError";
import { pb } from "@/utils/PocketBaseAdapter";
import { moment } from "@/utils/useTimeZone";
import { requiredValidator } from "@validators";
import isEmpty from "lodash/isEmpty";
import { useRouter } from "vue-router";
import { useTheme } from "vuetify";
import { VForm } from "vuetify/components/VForm";
import QrCode from "./QrCode.vue";
import type { BookingEmit, BookingProps } from "./types";
import { useBooking } from "./useBooking";
import { useUserStore } from "@/utils/UserStore";

// props
const props = defineProps<BookingProps>();

// Emit
const emit = defineEmits<BookingEmit>();

const vuetifyTheme = useTheme();

// Store
const userStore = useUserStore();
const globalErrorStore = useGlobalErrorStore();
const bookingStore = useBookingStore();
const paymentStore = usePaymentStore();
const discount = useDiscountCode();
const { handleUpdateErrorMessage } = globalErrorStore;

const { globalErrorMessage, isBookingWarningDialogVisible, errorDateList } =
  storeToRefs(globalErrorStore);
const { docketId, docketLineId, isPendingBooking } = storeToRefs(paymentStore);
const { recurringBookingId, activityList, areaLists } =
  storeToRefs(bookingStore);
const { discountCode, discountCodeData } = storeToRefs(discount);
const { currentLocationDetails } = storeToRefs(userStore);
const { getCurrentLocation } = userStore;

// composable
const booking = useBooking();

const {
  paymentOptions,
  daysOfWeek,
  recurringWeeks,
  recurringEndDateConfig,
  getAreaSingular,
  alternativeAreaList,
  getDuration,
  reserveSlot,
  removeExistingPendingBooking,
  updateBooking,
  refetchBooking,
  startBooking,
  handleRecurringBooking,
  getDayIdFromDate,
  handleTicketPrint,
} = booking;

// Router
const router = useRouter();

// Refs
const dateTimeForRef = ref<any>("");
const memberVisitorsFormRef = ref<any>("");
const agreementFormRef = ref<any>("");
const refRecurringVForm = ref<any>("");

// Data
const isBookingViewOnly = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const printTicketIsVisible = ref<boolean>(false);
const isYetToPayDues = ref<boolean>(false);
const isEditingBooking = ref<boolean>(false);
const form = ref<any>({
  id: "",
  title: "",
  date: null,
  activity: "",
  guests: [],
  area: null,
  time: null,
  notes: "",
  members: [],
  agreement: false,
  notifyMe: false,
  owner: "",
  isRecurring: false,
});
const recurring = ref<any>({
  frequency: 1,
  interval: "Weeks",
  days: [],
  endDate: "",
});
const maxPlayers = ref<number>(0);
const maxVisitors = ref<number>(0);
const minPlayers = ref<number>(0);
const minVisitors = ref<number>(0);
const role = ref<string>("Member");
const bookingCreatedDate = ref<any>("");
const currentStep = ref<number>(0);
const pendingBookingId = ref<any>("");
const showPopup = ref<boolean>(false);
const actualEndTime = ref<any>("");
const isDeleteDialogVisible = ref<boolean>(false);
const isCancelDialogVisible = ref<boolean>(false);
const selectedDurationSlot = ref<number>(0);
const isLeftHandedWarningDialogVisible = ref<boolean>(false);
const isLeftHandedBookingErrorVerified = ref<boolean>(false);
let inactivityTimer: ReturnType<typeof setTimeout>;
let inactivity: ReturnType<typeof setTimeout>;
const isQrCodeVisible = ref<boolean>(false);
const selectedBookingId = ref<string>("");

// computed
const bookingViewDetail = computed(() => {
  const startDate = props.booking?.startDate;
  const formattedDate = startDate
    ? moment(startDate).isSame(moment(), "day")
      ? `Today ${moment(startDate).format("h:mm A")}`
      : `${moment(startDate).format("dddd DD-MM-YY h:mm A")}`
    : "Unknown Start Time";

  return {
    activity:
      activityList.value?.find(
        (activity) => activity.id === props.booking?.activity
      )?.name || "Activity",
    bay:
      areaLists.value?.find((area) => area.id === props.booking?.area)
        ?.areaNumber || "unknown",
    startDate: formattedDate,
    duration: props.booking?.duration,
    members: props.memberList
      ?.filter((user) => props.booking?.users?.includes(user.id))
      .map((user) => `${user.firstName} ${user.lastName}`),
    visitors: props.booking?.visitors,
  };
});

const bookingSteps = computed(() => [
  {
    id: 1,
    title: "Date & Time",
    subtitle: "",
    icon: "tabler-calendar",
    size: 30,
    isValid: true,
  },
  {
    id: 2,
    title: "Players",
    subtitle: "",
    icon: "tabler-users",
    size: 30,
    isValid: true,
  },
  ...(form.value.isRecurring
    ? [
        {
          id: 3,
          title: "Recurring Booking",
          subtitle: "",
          icon: "tabler-repeat",
          size: 30,
          isValid: true,
        },
      ]
    : []),
  ...(!isInProgressBooking.value
    ? [
        {
          id: 4,
          title: "Agreement",
          subtitle: "",
          icon: "mdi-file-document-edit",
          size: 30,
          isValid: true,
        },
      ]
    : []),
  {
    id: 5,
    title: "Payment",
    subtitle: "Click save and pay button.",
    icon: "tabler-cards",
    size: 30,
    isValid: false,
  },
]);

// Computed
const haveEditAccess = computed(() => {
  return props?.permission?.includes("Edit");
});

const haveCreateAccess = computed(() => {
  return props?.permission?.includes("Create");
});

const isEditBooking = computed(() => props?.booking?.id);

const bookingStatus = computed(() => props?.booking?.status);

// Example booking space value, can be 15, 30, 45, or 60
const getBookingSpacing = computed(
  () => selectedActivity.value?.bookingSpacing || 60
);

const selectedActivity = computed(() =>
  props?.activityList?.find((el) => el.id === form.value.activity)
);

// Example booking space value, can be 15, 30, 45, or 60
const getBookingCancellationHour = computed(
  () => selectedActivity.value?.cancellationHours
);

const getMemberList = computed(() => {
  return props?.memberList;
});

const getAgreementMessage = computed(
  () => selectedActivity.value?.bookingAgreement
);

const getBookingGap = computed(() => {
  return selectedActivity.value?.bookingGap || 5;
});

const getBookingDiscount = computed(() => {
  return props?.booking?.discount || 0;
});

const isInProgressBooking = computed(() => {
  return props?.booking?.status === "inprogress";
});

const getDurationList = computed(() => {
  if (!form.value.activity) {
    return [];
  }
  const durationList = [];
  const timeFormat = getBookingSpacing.value % 60 === 0 ? "H" : "H:mm"; // Numeric format for whole hours

  // Calculate total slots
  const numberOfSlots = Math.min(4, 240 / getBookingSpacing.value); // Limit to 4 items max

  // Starting at the first interval
  const startTime = moment()
    .startOf("day")
    .add(getBookingSpacing.value, "minutes");

  for (let i = 0; i < numberOfSlots; i++) {
    const timeSlot = startTime
      .clone()
      .add(i * getBookingSpacing.value, "minutes")
      .format(timeFormat);
    durationList.push(timeSlot);
  }

  return durationList;
});

// configure disable condition
// Any of these one required for edit access
// Role other than Admin
const isActionButtonDisabled = computed(() => {
  if (form.value.id) {
    if (haveEditAccess.value) {
      // admin so allow them to edit
      return false;
    } else if (props?.booking?.users?.includes(props?.userId)) {
      if (bookingStatus.value === "futureBooking") {
        // future booking so allow them to edit
        return false;
      } else {
        return true;
      }
    } else return true;
  } else {
    return false;
  }
});

const isFieldsDisabled = computed(() => {
  if (form.value.id) {
    if (bookingStatus.value !== "futureBooking") {
      return true;
    }
    return false;
  }
  return false;
});

const areaDurationDisable = computed(() => {
  if (form.value.id) {
    if (bookingStatus.value !== "futureBooking") {
      if (haveEditAccess.value) {
        return false;
      }
      return true;
    }
    return false;
  }
  return false;
});

const timeList = computed(() => {
  const times = [];
  if (!form.value.date) {
    return [];
  }

  const interval = getBookingSpacing.value;

  const isToday = moment(form.value?.date, "DD-MM-YYYY").isSame(
    moment(),
    "day"
  );

  const filterPastTimes = (hour: any, min: any) => {
    if (!isToday) return true;

    const currentTime = moment();
    const timeToCheck = moment().hour(hour).minute(min);

    return timeToCheck.isAfter(currentTime, "minute");
  };

  const formatTimeTo12Hour = (hour: any, min: any) => {
    return moment(`${hour}:${min}`, "HH:mm").format("hh:mm A");
  };

  const dayName = form.value.date
    ? moment(form.value.date, "DD-MM-YYYY").format("dddd").toLowerCase()
    : "sunday";

  const filteredTimes = availableTimes.value?.filter(
    (timeSlot: any) => timeSlot[dayName]
  );

  if (
    role.value === "Admin" ||
    haveEditAccess.value ||
    haveCreateAccess.value
  ) {
    // Define a buffer for current slot handling.
    const bufferMinutes = 30;

    for (let hour = 0; hour < 24; hour++) {
      for (let min = 0; min < 60; min += interval) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMin = min.toString().padStart(2, "0");
        const time = `${formattedHour}:${formattedMin}`;

        // Get the current time in minutes only if the selected date is today.
        const currentTimeInMinutes = isToday
          ? moment().hour() * 60 + moment().minute()
          : 0; // For non-today dates, skip time-based filtering.
        const timeInMinutes = hour * 60 + min;

        // Adjust for buffer: Allow the current slot if within the buffer window, only for today.
        if (isToday && timeInMinutes + bufferMinutes < currentTimeInMinutes) {
          continue;
        }

        // Check if the time slot is available.
        const isAvailable = filteredTimes.some((timeSlot: any) => {
          const startTime = timeSlot.startHour * 60 + timeSlot.startMin;
          const endTime = timeSlot.finishHour * 60 + timeSlot.finishMin;

          // Handle cases where startHour or finishHour is 0
          if (startTime === 0 && endTime === 0) {
            return true;
          } else if (startTime === 0) {
            return timeInMinutes < endTime;
          } else if (endTime === 0) {
            return timeInMinutes >= startTime;
          }

          // Check if time is within start and end
          if (endTime > startTime) {
            return timeInMinutes >= startTime && timeInMinutes < endTime;
          }
          if (endTime <= startTime) {
            return timeInMinutes >= startTime || timeInMinutes < endTime;
          }

          return false;
        });

        times.push({
          label: formatTimeTo12Hour(hour, min),
          value: time,
          color: !isAvailable
            ? vuetifyTheme.name.value === "dark"
              ? "rgb(72, 72, 72)"
              : "lightgrey"
            : "",
          isNormalHour: isAvailable,
        });
      }
    }
  } else {
    const dynamicTimeList = new Set();
    filteredTimes?.forEach((timeSlot: any) => {
      const { startHour, finishHour } = timeSlot;

      if (startHour === 0 && finishHour === 0) {
        // Full-day availability
        for (let hour = 0; hour < 24; hour++) {
          for (let min = 0; min < 60; min += interval) {
            if (isToday && !filterPastTimes(hour, min)) continue;

            const formattedHour = hour.toString().padStart(2, "0");
            const formattedMin = min.toString().padStart(2, "0");
            dynamicTimeList.add(`${formattedHour}:${formattedMin}`);
          }
        }
      } else if (startHour === 0) {
        // Start at midnight and go until `finishHour`
        for (let hour = 0; hour < finishHour; hour++) {
          for (let min = 0; min < 60; min += interval) {
            if (isToday && !filterPastTimes(hour, min)) continue;

            const formattedHour = hour.toString().padStart(2, "0");
            const formattedMin = min.toString().padStart(2, "0");
            dynamicTimeList.add(`${formattedHour}:${formattedMin}`);
          }
        }
      } else if (finishHour === 0) {
        // Start at `startHour` and go until midnight
        for (let hour = startHour; hour < 24; hour++) {
          for (let min = 0; min < 60; min += interval) {
            if (isToday && !filterPastTimes(hour, min)) continue;

            const formattedHour = hour.toString().padStart(2, "0");
            const formattedMin = min.toString().padStart(2, "0");
            dynamicTimeList.add(`${formattedHour}:${formattedMin}`);
          }
        }
      } else {
        // Normal range
        for (let hour = startHour; hour < finishHour; hour++) {
          for (let min = 0; min < 60; min += interval) {
            if (isToday && !filterPastTimes(hour, min)) continue;

            const formattedHour = hour.toString().padStart(2, "0");
            const formattedMin = min.toString().padStart(2, "0");
            dynamicTimeList.add(`${formattedHour}:${formattedMin}`);
          }
        }
      }
    });

    return Array.from(dynamicTimeList)
      .sort()
      .map((time: any) => ({
        label: formatTimeTo12Hour(time.split(":")[0], time.split(":")[1]),
        value: time,
        color: vuetifyTheme.name.value,
        isNormalHour: true,
      }));
  }

  return times;
});

const isInCurrentTimeSlot = computed(() => {
  if (haveCreateAccess.value) {
    return false;
  }

  const currentTime = moment();
  const bookingStart = moment(
    `${form.value?.date} ${form.value?.time}`,
    "DD-MM-YYYY HH:mm"
  );

  // Determine the duration based on activity type
  const activity = activityList.value?.find(
    (activity) => activity.id === form.value?.activity
  );

  const bookingDuration = activity?.bookingSpacing;
  const bookingEnd = bookingStart.clone().add(bookingDuration, "minutes");

  return currentTime.isBetween(bookingStart, bookingEnd);
});

const isOutHourWarningVisible = computed(() => {
  if (haveCreateAccess.value) {
    return false;
  }

  const dayName = form.value.date
    ? moment(form.value.date, "DD-MM-YYYY").format("dddd").toLowerCase()
    : "sunday";

  const filteredTimes = availableTimes.value?.filter((timeSlot: any) => {
    if (!timeSlot[dayName]) {
      return false;
    }

    // Exclude slots where start and finish times are both 0
    if (timeSlot.startHour === 0 && timeSlot.finishHour === 0) {
      return false;
    }

    return true;
  });

  if (!filteredTimes || filteredTimes.length === 0) {
    return true;
  }

  const bookingTime = (() => {
    const [hours, minutes] = form.value?.time?.split(":")?.map(Number);
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  })();

  const isOutOfRange = !filteredTimes.some((timeSlot: any) => {
    const startTime = timeSlot.startHour * 60 + timeSlot.startMin;
    const endTime = timeSlot.finishHour * 60 + timeSlot.finishMin;

    // Adjust for overnight time slots
    const isOvernight = timeSlot.startHour > timeSlot.finishHour;
    const isValid = isOvernight
      ? bookingTime >= startTime || bookingTime < endTime
      : bookingTime >= startTime && bookingTime < endTime;

    return isValid;
  });

  return isOutOfRange;
});

const getAreaList = computed(() => {
  if (form.value.activity) {
    return props.areaList?.filter((area: any) =>
      area.activities.includes(form.value.activity)
    );
  }
  return [];
});

const availableTimes = computed(() => {
  const activity = selectedActivity.value?.expand?.availibleTimes || [];
  const userMemberships = [props?.membership?.id];
  return activity.filter((timeSlot: any) => {
    if (timeSlot.memberships && timeSlot.memberships.length > 0) {
      return timeSlot?.memberships?.some((membership: any) =>
        userMemberships.includes(membership)
      );
    }
    return true;
  });
});

const datePickerConfig = computed(() => {
  let disabledDays = ref([...Array(7).keys()]);
  if (availableTimes.value.length) {
    availableTimes.value?.forEach((time: any) => {
      daysOfWeek.forEach((day, index) => {
        if (time[day]) {
          disabledDays.value = disabledDays.value?.filter(
            (d: any) => d !== index
          );
        }
      });
    });
  } else {
    disabledDays.value = [];
  }
  if (role.value === "Admin" || haveCreateAccess.value) {
    return {
      dateFormat: "d-m-Y",
      disable: [
        function (date: any) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset the time part to just compare dates

          if (bookingStatus.value === "futureBooking" || !form.value.id) {
            // Disable past dates and any dates in the disabledDays array
            return date < today;
          }
        },
      ],
    };
  } else {
    return {
      dateFormat: "d-m-Y",
      disable: [
        function (date: any) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset the time part to just compare dates

          if (bookingStatus.value === "futureBooking" || !isEditBooking.value) {
            // Disable past dates and any dates in the disabledDays array
            return date < today || disabledDays.value.includes(date.getDay());
          } else {
            // Disable only the specific days in the disabledDays array
            return disabledDays.value.includes(date.getDay());
          }
        },
      ],
    };
  }
});

const isStartButtonDisabled = computed(() => {
  // if the time now is no more than 45 mins before the bookingStartTime
  // and no more than 30 mins after the bookingStartTime, otherwise hide the button
  const { date, time } = form.value;
  if (!date || !time) return true;
  const bookingStartTime = moment(date).set({
    hour: parseInt(time.split(":")[0], 10),
    minute: parseInt(time.split(":")[1], 10),
  });
  const diffInMinutes = moment().diff(bookingStartTime, "minutes");
  return !(diffInMinutes >= -45 && diffInMinutes <= 30);
});

const isLeftHandWarning = computed(() => {
  // Check if required data is available
  if (!form.value?.area || !form.value?.members?.length) {
    return false;
  }
  // Find the area object based on the selected area ID
  const selectedArea = props?.areaList?.find(
    (area) => area.id === form.value.area
  );
  // If the selected area cannot accommodate left-handed members
  if (!selectedArea?.canLeftHanded) {
    // Check if any of the selected members is left-handed
    const isLeftHandedMemberSelected = props?.memberList?.some(
      (member) => form.value.members.includes(member.id) && member.leftHanded
    );
    // Return true if a left-handed member is selected
    return isLeftHandedMemberSelected;
  }
  // If the selected area can accommodate left-handed members, return false
  return false;
});

const durationExtendList = computed(() => {
  const durationList = [] as any;

  // Convert duration to minutes and calculate the end limit
  const durationTotal = mapSelectionToMinutes(
    String(form.value.duration)?.replace(":", ".")
  );

  const endLimit = moment(props?.booking?.startDate).add(
    durationTotal,
    "minutes"
  );

  // Get the actual start time and actual duration in minutes
  const startTime = moment(
    form.value?.actualStartTime,
    "DD-MM-YYYY hh:mm a",
    true
  );

  // Calculate the limit for the largest duration, considering the bookingSpace
  const bookingSpace = 5; // Adjust this value based on your bookingSpace requirements
  let nextDuration =
    props?.booking?.actualduration < props?.booking?.duration
      ? props?.booking?.actualduration
      : props?.booking?.duration - bookingSpace;
  const maxDuration = endLimit.diff(startTime, "minutes") - bookingSpace - 1;

  // Generate duration list with increments of 5 minutes
  while (nextDuration <= maxDuration) {
    durationList.push(nextDuration);
    nextDuration += 5;
  }

  // If the final duration is within the maxDuration, add any remaining minutes
  if (durationList.length > 0 && nextDuration - 5 < maxDuration) {
    const remainingMinutes = maxDuration - (nextDuration - 5);
    if (remainingMinutes > 0) {
      durationList.push(
        durationList[durationList.length - 1] + remainingMinutes
      );
    }
  }
  return durationList;
});

const getBookingAmount = computed(() => {
  if (form.value?.activity && form.value?.area && form.value?.duration) {
    const durationTotal = mapSelectionToMinutes(
      String(form.value?.duration)?.replace(":", ".")
    );
    const numberOfSlots = Math.ceil(durationTotal / getBookingSpacing.value);
    const amount = numberOfSlots * selectedActivity?.value?.sessionFullPrice;
    return amount;
  }
  return 0;
});

/**
 * Computes and returns the global info message for the booking form.
 *
 * @returns {Object} - The global info message object with properties: color, icon, message, paymentOptions.
 */
const globalInfoMessage = computed(() => {
  const totalPaid = props?.booking?.paid || 0;
  const discountAmount =
    getBookingAmount.value * (getBookingDiscount.value / 100);
  const calculatedValue = (
    getBookingAmount.value -
    totalPaid -
    discountAmount
  ).toFixed(2);

  const isDue = props?.booking?.due;
  isYetToPayDues.value = !!isDue;
  const payOptions =
    haveCreateAccess.value || haveEditAccess.value
      ? paymentOptions.value
      : paymentOptions.value.filter(
          (option) => !["pos", "waiveFee"].includes(option.value)
        );

  const baseMessage = {
    color: "dialogHeaderColor",
    message: `$${calculatedValue} - Play or practice with up to ${selectedActivity?.value?.maxPlayers} Players`,
    paymentOptions: isDue ? payOptions : undefined,
  };
  if (currentStep.value === 1) {
    return {
      ...baseMessage,
      message: `You can select a maximum of ${maxPlayers.value} Players from Members and Guests`,
    };
  }
  if (isEditBooking.value) {
    const statusColorName = isDue
      ? bookingStatus.value === "futureBooking"
        ? "warning"
        : "error"
      : "dialogHeaderColor";

    const statusIcon = isDue
      ? bookingStatus.value === "futureBooking"
        ? "tabler-alert-triangle"
        : "tabler-xbox-x"
      : "";

    return {
      ...baseMessage,
      color: statusColorName,
      icon: statusIcon,
      message: isDue
        ? `The Session fee of $${calculatedValue} has not been paid yet`
        : props?.booking?.payMethod?.length > 1
        ? `Booking paid by ${props.booking.payMethod.join(", ")}`
        : props?.booking?.payMethod?.length === 1 &&
          props.booking.payMethod[0] === "Fee Waived"
        ? "Booking fee has been waived."
        : "The Session has been fully paid.",
    };
  }
  return baseMessage;
});

const getMaxBookAhead = computed(() => {
  const location = props?.locationList?.find(
    (location: any) => location?.id === props?.currentLocation
  );
  return recurring?.value?.interval === "Weeks"
    ? location?.maxBookAheadWeeks
    : recurring?.value?.interval === "Months"
    ? location?.maxBookAheadWeeks / 4
    : 0;
});

// Methods
const validateMemberVisitors = async () => {
  /**
   * Validates the number of members and guests selected in the booking form.
   *
   * @returns {Promise<boolean>} - Returns true if the validation is successful, false otherwise.
   */
  // Calculate the number of selected members
  const selectedMembers = form.value.members?.length ?? 0;

  // Calculate the number of selected guests
  const selectedGuests = form.value.guests?.length ?? 0;

  // Check if the total number of selected members and guests is less than the minimum number of players
  if (selectedMembers + selectedGuests < minPlayers.value) {
    // Display an error message if the condition is true
    let message = `You must select at least ${minPlayers.value} players.`;
    handleUpdateErrorMessage(true, message);
    return false;
  }

  // Check if the total number of selected members and guests is greater than the maximum number of players
  if (selectedMembers + selectedGuests > maxPlayers.value) {
    // Display an error message if the condition is true
    let message = `You have selected too many players.`;
    handleUpdateErrorMessage(true, message);
    return false;
  }

  // Clear the error message if the validation is successful
  handleUpdateErrorMessage(false, "");
  return true;
};

const validateTermAndCondition = async () => {
  if (isInProgressBooking.value) {
    return true;
  }
  // Check if the user has agreed to the terms
  if (!form.value?.agreement) {
    let message = "You must agree to our booking terms to continue";
    handleUpdateErrorMessage(true, message);
    return false;
  }
  // If the terms are agreed, no error message is needed
  handleUpdateErrorMessage(false, "");
  return true;
};

/**
 * Validates the duration of a booking.
 *
 * @returns {Promise<boolean>} - Returns true if the duration is valid, false otherwise.
 */
const validateDuration = async () => {
  // If the user has create access, return true
  if (haveCreateAccess.value) {
    return true;
  }

  // Calculate the total duration in minutes
  const durationTotal = mapSelectionToMinutes(
    String(form.value.duration)?.replace(":", ".")
  );

  let start, finish;
  // Filter the available times for the selected activity and day of the week
  const timeRanges = selectedActivity?.value?.expand?.availibleTimes;
  const dayOfWeek = moment(form.value?.date, "DD-MM-YYYY")
    .format("dddd")
    ?.toLowerCase();

  const timeRange = timeRanges?.filter((el: any) => el[dayOfWeek] === true);

  // If there is a time range, get the start and finish hours
  if (timeRange?.length) {
    start = Math.min(...timeRange?.map((el: any) => el.startHour));
    finish = Math.max(...timeRange?.map((el: any) => el.finishHour));
  }

  // Extract the time from the form
  const { time } = form.value;
  const minutes = parseInt(durationTotal, 10);

  // Parse the time and clone it to avoid mutation
  const startTime = moment(time, "HH:mm");
  const endTime = startTime.clone().add(minutes, "minutes");

  // Adjust finishHour if it is midnight (0)
  let adjustedFinishHour = finish === 0 ? 24 : finish;
  const finishTime = moment(`${adjustedFinishHour}:00`, "HH:mm");

  // If the end time is after the finish time, display an error message
  if (endTime.isAfter(finishTime)) {
    // Format hours to display as "hh:mm A"
    const formattedStartHour = moment(`${start}:00`, "HH:mm").format("h:mm A");
    const formattedFinishHour = moment(`${finish}:00`, "HH:mm").format(
      "h:mm A"
    );

    handleUpdateErrorMessage(
      true,
      `Sorry, the selected time is outside of our normal operating hours. Please choose a time between ${formattedStartHour} and ${formattedFinishHour}.`
    );
    return false;
  } else if (isInCurrentTimeSlot.value) {
    handleUpdateErrorMessage(
      true,
      `Sorry, the booking is in the current time slot and cant be changed.`
    );
    return false;
  } else {
    // If the end time is within the operating hours, clear the error message
    handleUpdateErrorMessage(false, "");
    return true;
  }
};

/**
 * Validates the recurring booking form.
 *
 * @returns {Promise<boolean>} - Returns true if the form is valid, false otherwise.
 */
const validateRecurring = async () => {
  // If the booking is not recurring, return true
  if (!form.value?.isRecurring) {
    return true;
  }

  // Destructure the recurring values from the form
  const { interval, endDate, frequency, days } = recurring?.value;

  // Define the required fields and their corresponding error messages
  const requiredFields = [
    {
      field: interval,
      message: "Please select a recurring interval.",
    },
    {
      field: frequency,
      message: "Please specify the recurring frequency.",
    },
    {
      field: days && days?.length > 0,
      message: "Please select at least one recurring day.",
    },
    {
      field: endDate,
      message: "Please specify the recurring end time.",
    },
  ];

  // Find the first missing field
  const missingField = requiredFields.find(({ field }) => !field);

  // If a missing field is found, update the error message and return false
  if (missingField) {
    handleUpdateErrorMessage(true, missingField.message);
    return false;
  }

  // If no missing fields are found, return true
  return true;
};

const mapSelectionToMinutes = (selectedValue: any) => {
  // Ensure the input is a string
  selectedValue = selectedValue.toString();
  // If the input is a whole number (e.g., "1"), treat it as hours
  if (!selectedValue.includes(".")) {
    return Number(selectedValue) * 60;
  }
  // Split the time string and convert to minutes
  const [hours = "0", minutes = "0"] = selectedValue.split(".").map(Number);
  return hours * 60 + minutes;
};

/**
 * Handles the submission of the booking form.
 * Validates all forms, updates the booking details, and makes an API call to update the booking.
 *
 * @returns {Promise<void>}
 */
const handleSubmit = async () => {
  // Validate all forms
  const { valid } = await dateTimeForRef.value?.validate();

  const durationTotal = mapSelectionToMinutes(
    String(form.value.duration)?.replace(":", ".")
  );

  if (!valid) {
    return;
  }

  // Validate member visitors terms conditions and time duration
  if (
    !(await validateMemberVisitors()) ||
    !(await validateTermAndCondition()) ||
    !(await validateDuration()) ||
    !(await validateRecurring())
  ) {
    return;
  }

  selectedDurationSlot.value = durationTotal / getBookingSpacing.value;

  const bookingDetails = {
    ...form.value,
    date: form.value?.date,
    due: (
      getBookingAmount.value -
      (props?.booking?.paid || 0) -
      getBookingAmount.value * (getBookingDiscount.value / 100)
    ).toFixed(2),
    value: getBookingAmount.value,
    id: pendingBookingId.value || form.value.id,
    updatedBy: props?.userId,
    duration: durationTotal,
    actualStartTime: form.value?.actualStartTime
      ? moment(form.value?.actualStartTime, "DD-MM-YYYY hh:mm a").isValid()
        ? moment(
            form.value?.actualStartTime,
            "DD-MM-YYYY hh:mm a"
          ).toISOString()
        : null
      : null,
    actualDuration: props?.booking?.actualDuration
      ? form.value?.actualDuration
      : null,
    slot: selectedDurationSlot.value,
    sessionPrice: selectedActivity?.value?.sessionFullPrice,
    recurring: form.value.isRecurring
      ? {
          id: props?.recurringDetails?.recurringId || recurringBookingId.value,
          isEditAll: props?.recurringDetails?.isEditAll
            ? props?.recurringDetails?.isEditAll
            : false,
          interval: recurring.value?.interval,
          frequency: recurring?.value?.frequency,
          days: recurring.value?.days,
          endDate: moment(
            recurring?.value?.endDate,
            "DD-MM-YYYY"
          ).toISOString(),
        }
      : {},
  };
  showPopup.value = false;
  pendingBookingId.value = null;

  try {
    isLoading.value = true;
    await updateBooking(bookingDetails);

    emit("reloadBookingList", true);

    clearPendingTimer();
    if (!errorDateList.value.length) {
      // Goto payment tab
      currentStep.value = bookingSteps?.value?.length - 1;
    }
  } catch (e: any) {
    const { message } = e?.response;
    if (message) {
      handleUpdateErrorMessage(true, message);
    }
  } finally {
    isLoading.value = false;
  }
};

const createUpdateRecurringBookings = async () => {
  /**
   * Handles the creation or update of recurring bookings.
   *
   * @returns {Promise<void>}
   */
  const durationTotal = mapSelectionToMinutes(
    String(form.value.duration)?.replace(":", ".")
  );
  selectedDurationSlot.value = durationTotal / getBookingSpacing.value;
  const bookingDetails = {
    ...form.value,
    date: form.value.date,
    due: (
      getBookingAmount.value -
      (props?.booking?.paid || 0) -
      getBookingAmount.value * (getBookingDiscount.value / 100)
    ).toFixed(2),
    value: getBookingAmount.value,
    id: pendingBookingId.value || form.value.id,
    loggedInUser: props?.userId,
    duration: durationTotal,
    actualStartTime: form.value?.actualStartTime
      ? moment(form.value?.actualStartTime).toISOString()
      : null,
    actualDuration: props?.booking?.actualDuration,
    slot: selectedDurationSlot.value,
    sessionPrice: selectedActivity?.value?.sessionFullPrice,
    recurring: form.value.isRecurring
      ? {
          id: props?.recurringDetails?.recurringId || recurringBookingId.value,
          isEditAll: props?.recurringDetails?.isEditAll
            ? props?.recurringDetails?.isEditAll
            : false,
          interval: recurring.value?.interval,
          frequency: recurring?.value?.frequency,
          days: recurring.value?.days,
          endDate: moment(
            recurring?.value?.endDate,
            "DD-MM-YYYY"
          ).toISOString(),
        }
      : {},
  };
  showPopup.value = false;
  pendingBookingId.value = null;

  try {
    await handleRecurringBooking(bookingDetails);
    if (!errorDateList.value.length) {
      // Goto agreement tab
      currentStep.value++;
    }
  } catch (e: any) {
    const { message } = e?.response;
    if (message) {
      handleUpdateErrorMessage(true, message);
    }
  }
};

const getMaxActivityVisitors = () => {
  /**
   * Updates the maximum number of players and visitors based on the selected activity.
   *
   * @returns {void}
   */
  // Find the selected activity in the activity list
  const activity = props?.activityList?.find(
    (item: any) => item.id === form.value.activity
  );

  // Update the maximum number of players and visitors based on the selected activity
  maxPlayers.value = activity?.maxPlayers;
  maxVisitors.value = activity?.maxVisitors;
  minPlayers.value = activity?.minPlayers;
  minVisitors.value = activity?.minVisitors;
};

const handleMemberUpdate = (newValue: any) => {
  /**
   * Handles the update of the members in the booking form.
   *
   * @param {Array} newValue - The new array of member IDs.
   * @returns {void}
   */
  const message = { isVisible: false, message: "" };

  // Check if an activity is selected
  if (!form.value?.activity) {
    message.isVisible = true;
    message.message = "Please select activity first";
    form.value.members = [];
  } else {
    // Calculate the number of selected guests
    const selectedGuests = form.value?.guests?.length ?? 0;

    // Calculate the maximum allowed number of members
    const maxAllowedMembers = maxPlayers.value - selectedGuests;

    // Check if the total number of members exceeds the allowed limit
    if (newValue.length > maxAllowedMembers) {
      message.isVisible = true;
      message.message = `The total number of Players cannot exceed ${maxPlayers.value}.`;

      // Limit the members array to the maximum allowed number of members
      form.value.members = newValue.slice(0, maxAllowedMembers);
    } else {
      // Update the members array with the new value
      form.value.members = newValue;
    }
  }

  // Call the function to handle the update of the error message
  handleUpdateErrorMessage(message?.isVisible, message?.message);
};

const handleGuestUpdate = (newValue: any) => {
  /**
   * Handles the update of the guests in the booking form.
   *
   * @param {Array} newValue - The new array of guest IDs.
   * @returns {void}
   */
  // Check if an activity is selected
  if (!form.value?.activity) {
    // Clear the guests array and return if no activity is selected
    handleUpdateErrorMessage(false, "");
    form.value.guests = [];
    return;
  }

  // Calculate the number of selected members
  const selectedMembers = form.value?.members ? form.value?.members?.length : 0;

  // Check if the total number of guests and members exceeds the allowed limit
  if (newValue.length + selectedMembers > maxPlayers.value) {
    // Limit the guests array to the maximum allowed number of guests
    form.value.guests = newValue.slice(0, maxPlayers.value - selectedMembers);

    // Display an error message if the limit is exceeded
    let message = `The total number of members and guests cannot exceed ${maxPlayers.value}.`;
    handleUpdateErrorMessage(true, message);
  } else {
    // Update the guests array with the new value
    form.value.guests = newValue;

    // Clear the error message if the limit is not exceeded
    handleUpdateErrorMessage(false, "");
  }
};

const assignRole = () => {
  /**
   * Assigns the role based on the current logged-in user's roles at the selected location.
   *
   * @returns {void}
   */
  // Filter the current logged-in user's roles for the selected location
  let currentLocationRole = props?.currentLoggedInRoles?.filter(
    (role) => role.location === form.value.location
  );

  // If the current logged-in user has roles at the selected location
  if (currentLocationRole?.length) {
    // If the current logged-in user has an admin role at the selected location
    if (currentLocationRole.some((role) => role.name?.includes("Admin"))) {
      // Assign the admin role
      role.value = "Admin";
    } else if (
      // If the current logged-in user has a staff role at the selected location
      currentLocationRole.some((role) => role.name?.includes("Staff"))
    ) {
      // Assign the member role
      role.value = "Member";
    } else {
      // If the current logged-in user has no admin or staff role at the selected location
      // Assign the user role
      role.value = "User";
    }
  } else {
    // If the current logged-in user has no roles at the selected location
    // Assign an empty string as the role
    role.value = "";
  }
};

const confirmCloseBooking = async () => {
  /**
   * Handles the closing of the booking dialog and clears the form data.
   *
   * @returns {Promise<void>}
   */
  // Check and remove any pending state booking
  await removeExistingPendingBooking(
    pendingBookingId.value || props?.booking?.id
  );

  // Clear the form data
  form.value = {
    id: "",
    title: "",
    date: null,
    activity: "",
    guests: [],
    area: null,
    time: null,
    notes: "",
    members: [],
    agreement: false,
    notifyMe: false,
    owner: "",
    isRecurring: false,
  };

  // Reset the current step to the first step
  currentStep.value = 0;

  // Close the booking dialog
  showPopup.value = false;

  // Clear the pending booking ID
  pendingBookingId.value = null;

  // Clear the recurring booking ID
  recurringBookingId.value = null;

  // Reset the recurring form data
  recurring.value = {
    frequency: 1,
    interval: "Weeks",
    days: [],
    endDate: "",
  };

  // Clear the error date list
  errorDateList.value = [];

  // Close the booking warning dialog
  isBookingWarningDialogVisible.value = false;

  // Emit the closeDialog event with false as the parameter
  emit("closeDialog", false);
};

const closeDialog = async () => {
  if (
    pendingBookingId.value &&
    bookingSteps?.value?.length - 1 !== currentStep?.value
  ) {
    // show the confirmation message
    isCancelDialogVisible.value = true;
  } else {
    await confirmCloseBooking();
  }
};

// scroll to active hour in list
const handleMenuUpdate = (isOpen: boolean) => {
  if (isOpen) {
    setTimeout(() => {
      const overlayContainer = document?.querySelector(".select-menu-overlay");
      const list = overlayContainer?.querySelector(".v-list");
      if (list) {
        const normalHourElement = Array?.from(list.children)?.find((el) => {
          return el?.classList?.contains("is-normal-hour");
        });
        if (normalHourElement) {
          normalHourElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }, 200);
  }
};

const checkDelete = (isAll: boolean) => {
  /**
   * Handles the deletion of a booking.
   * Allows admins to delete any booking and users to delete only eligible bookings.
   *
   * @param {boolean} isAll - Indicates whether to delete all recurring bookings.
   * @returns {boolean} - Returns false if the booking is within the non-cancellation window.
   */

  if (moment(props.booking?.endTime).utc().isBefore(moment().utc())) {
    let message = "Unable to cancel the booking as it is in past time.";
    handleUpdateErrorMessage(true, message);
    isDeleteDialogVisible.value = false;
    return false;
  }

  let startTime = moment(
    `${form.value.date} ${form.value.time}`,
    "DD-MM-YYYY HH:mm"
  );

  let bookingCreateTime = moment(bookingCreatedDate.value);
  let differenceHour = getBookingCancellationHour.value;

  const isAdmin =
    role.value === "Admin" || haveEditAccess.value || haveCreateAccess.value;

  let hoursDifference = startTime.diff(moment(), "hours");
  let differenceInMinutes = bookingCreateTime.diff(moment(), "minutes");

  // Check if the booking is currently ongoing
  let endTime = startTime.clone().add(form.value.duration, "hours");
  let isCurrentBooking = moment().isBetween(startTime, endTime);

  if (isAdmin && isCurrentBooking) {
    // Allow admin to delete the current booking
    emit("deleteBooking", {
      bookingId: form.value.id,
      recurringId: props?.recurringDetails?.recurringId || "",
      isAll: Boolean(isAll),
    });
  } else if (
    differenceInMinutes <= 0 &&
    differenceInMinutes >= -15 &&
    !isCurrentBooking
  ) {
    // Allow deletion if the booking is new (created within the last 15 minutes)
    emit("deleteBooking", {
      bookingId: form.value.id,
      recurringId: props?.recurringDetails?.recurringId || "",
      isAll: isAll,
    });
  } else if (startTime.isAfter(moment()) && hoursDifference > differenceHour) {
    // Allow deletion if the startTime is in the future and more than differenceHour hours away
    emit("deleteBooking", {
      bookingId: form.value.id,
      recurringId: props?.recurringDetails?.recurringId || "",
      isAll: isAll,
    });
  } else {
    // Show error if it's within the non-cancellation window
    let message =
      "Unable to cancel the booking as it is within the non-cancellation window.";
    handleUpdateErrorMessage(true, message);
    isDeleteDialogVisible.value = false;
    return false;
  }
};

/**
 * Handles the navigation to the next step in the booking process.
 * Validates the current step's form inputs, updates the current step,
 * and handles the creation or update of bookings.
 *
 * @returns {Promise<void>}
 */
const goToNextStep = async () => {
  try {
    const validateBookingDateTime = async () => {
      // Validate the booking date, time, area, duration, and owner form inputs
      const { valid } = await dateTimeForRef.value.validate();
      if (!(await validateDuration())) {
        return;
      }
      if (valid) {
        if (!isEditBooking.value && !form.value?.isRecurring) {
          try {
            // Calculate the booking due amount
            const bookingDue = (
              getBookingAmount.value -
              (props?.booking?.paid || 0) -
              getBookingAmount.value * (getBookingDiscount.value / 100)
            ).toFixed(2);

            // Make a request to reserve the booking slot
            const response = await reserveSlot(
              form.value?.duration,
              form.value?.date,
              form.value?.time,
              form.value?.activity,
              form.value?.area,
              form.value?.owner,
              props?.userId,
              pendingBookingId.value,
              bookingDue
            );
            if (response) {
              // Update the pending booking ID
              pendingBookingId.value = response;
              // Move to the next step
              currentStep.value++;
              // Clear the error message
              handleUpdateErrorMessage(false, "");
            }
          } catch (e: any) {
            const { message } = e?.response;
            if (message) {
              handleUpdateErrorMessage(true, message);
            }
          }
        } else {
          // Move to the next step
          currentStep.value++;
        }
        // Add the owner as a member if it's a new booking
        if (!isEditBooking.value) {
          form.value.members = [form.value?.owner];
          recurring.value.days = [getDayIdFromDate(form.value.date)];
        }
      }
    };

    const validateMembersStep = async () => {
      // Validate the members and visitors form inputs
      let { valid } = await memberVisitorsFormRef.value?.validate();
      if (valid) {
        let isMemberVisitorsValid = await validateMemberVisitors();
        if (isMemberVisitorsValid) {
          // Check booking is in confirmed or not
          const { state } = props?.booking;
          if (state !== "Confirmed") {
            // Check booking have any members leftHanded or not
            if (
              isLeftHandWarning.value &&
              !isLeftHandedBookingErrorVerified.value
            ) {
              isLeftHandedWarningDialogVisible.value = true;
              return;
            }
          }
          // Move to the next step
          currentStep.value++;
        }
      }
    };

    const validateTermConditionStep = async () => {
      // Validate the term and conditions form inputs
      let { valid } = await agreementFormRef.value?.validate();
      if (valid) {
        let isTermConditionValid = await validateTermAndCondition();
        if (!isTermConditionValid) {
          return;
        }
        // Move to the next step
        currentStep.value++;
      }
    };

    if (currentStep.value === 0) {
      // Validate the booking date, time, area, duration, and owner form inputs
      await validateBookingDateTime();
    } else if (currentStep.value === 1) {
      // Validate the members and visitors form inputs
      await validateMembersStep();
    } else {
      if (form.value?.isRecurring) {
        if (currentStep.value === 2) {
          // Validate the recurring configuration form inputs
          let { valid } = await refRecurringVForm.value?.validate();
          if (valid && (await validateRecurring())) {
            await createUpdateRecurringBookings();
            // currentStep.value++;
          }
        } else if (currentStep.value === 3) {
          // Validate the term and conditions form inputs
          await validateTermConditionStep();
        }
      } else {
        if (currentStep.value === 2) {
          // Validate the term and conditions form inputs
          await validateTermConditionStep();
        }
      }
    }
  } catch (e) {}
};

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(showInactivityPopup, 2 * 60 * 1000); // 2 minutes
};

const showInactivityPopup = () => {
  showPopup.value = true;
};

const continueBooking = () => {
  showPopup.value = false;
  resetInactivityTimer();
};

const startInactivityTimer = () => {
  inactivity = setTimeout(async () => {
    showPopup.value = false;
    await confirmCloseBooking();
  }, 2 * 60 * 1000);
};

const clearInactivityTimer = () => {
  if (inactivity !== null) {
    clearTimeout(inactivity);
    inactivity = null;
  }
};

// only for testing purposes in local server
// duplicate code of save booking for testing
const handleStartBooking = async () => {
  // Validate all forms in parallel
  const [firstForm, secondForm, thirdForm] = await Promise.all([
    dateTimeForRef.value?.validate(),
    memberVisitorsFormRef.value?.validate(),
    agreementFormRef.value?.validate(),
  ]);
  if (!firstForm.valid || !secondForm.valid || !thirdForm.valid) return;
  const durationTotal = mapSelectionToMinutes(
    String(form.value.duration)?.replace(":", ".")
  );
  const bookingData = {
    ...form.value,
    date: moment(form.value.date).format("DD-MM-YYYY"),
    duration: durationTotal,
    due: (
      getBookingAmount.value -
      (props?.booking?.paid || 0) -
      getBookingAmount.value * (getBookingDiscount.value / 100)
    ).toFixed(2),
    value: getBookingAmount.value,
    id: pendingBookingId.value || form.value.id,
    updatedBy: props?.userId,
    actualStartTime: moment().toISOString(),

    actualDuration:
      moment().add(durationTotal, "minutes").diff(moment(), "minutes") -
      getBookingGap.value,
  };
  showPopup.value = false;
  pendingBookingId.value = null;
  // testing in local environment
  await startBooking(bookingData);
  emit("closeDialog", false);
};

const gotToPos = (docketId: string) => {
  /**
   * Function to navigate to the POS list screen.
   *
   * @param {string} docketId - The ID of the docket to navigate to.
   *
   * @emits {closeDialog} - Emits an event to close the booking dialog after navigating to the POS list screen.
   *
   * @returns {void}
   */
  // move user to pos list screen
  router.push({
    name: "pos",
    query: {
      did: docketId,
    },
  });
  setTimeout(() => {
    emit("closeDialog", false);
  }, 1000);
};

const handlePaymentSuccess = async () => {
  try {
    await refetchBooking();

    if (props?.booking?.due === 0) {
      // close booking dialog after 2 if got paid completely
      setTimeout(() => {
        emit("closeDialog", false);
      }, 2000);
    }
  } catch (e) {}
};

const selectRecurringItem = (item: any) => {
  /**
   * Handles the selection of a recurring booking day.
   *
   * @param {Object} item - The recurring day item to be selected.
   * @returns {void}
   */
  // Get the current selected days from the recurring form
  const { days } = recurring.value || {};

  // Get the maximum number of days that can be selected
  const maxItems = getMaxBookAhead?.value || 0;

  // If the selected day is already in the list and the list is not full, remove it
  if (!days?.includes(item?.id) && days?.length === maxItems) {
    handleUpdateErrorMessage(true, `You can only select up to ${maxItems}.`);
    return;
  }

  // If the selected day is not in the list, add it
  const index = days?.findIndex((i: any) => i === item.id);
  if (index !== -1) {
    recurring.value?.days?.splice(index, 1); // Remove if already selected
  } else {
    recurring.value?.days?.push(item.id); // Add if not selected
  }
};

const handlePaymentDues = () => {
  isBookingViewOnly.value = false;
  currentStep.value = bookingSteps?.value?.length - 1;
};

const clearPendingTimer = () => {
  showPopup.value = false;
  window.removeEventListener("mousemove", resetInactivityTimer);
  window.removeEventListener("keydown", resetInactivityTimer);
  window.removeEventListener("touchstart", resetInactivityTimer);
  window.removeEventListener("click", resetInactivityTimer);
  clearTimeout(inactivityTimer);
};

// Watch booking prop
watch(
  () => props.booking,
  (newValue) => {
    if (newValue && !isEmpty(newValue)) {
      // Clone the booking object to avoid direct mutations
      const bookingClone = { ...props.booking };

      // Assign values from booking to form fields
      docketId.value = bookingClone.docketId;
      docketLineId.value = bookingClone.docketLine;
      form.value = {
        id: bookingClone.id,
        activity: bookingClone.activity,
        area: bookingClone.area,
        date: moment(bookingClone.startDate).format("DD-MM-YYYY"),
        time: bookingClone.startDate
          ? moment(bookingClone.startDate).format("HH:mm")
          : null,
        notes: bookingClone.notes,
        members: bookingClone.users,
        owner: bookingClone.owner || null,
        guests: bookingClone.visitors
          ? bookingClone.visitors
              ?.split(",")
              ?.map((item: string) => item.trim())
          : null,
        duration:
          getBookingSpacing.value === 60
            ? bookingClone.duration / getBookingSpacing.value
            : getDuration(bookingClone.duration, getBookingSpacing.value),
        agreement:
          haveCreateAccess.value || bookingClone?.state === "Confirmed"
            ? true
            : false,
        notifyMe: false,
        isRecurring: !isEmpty(bookingClone.recurring),
      };

      // Handle "in-progress" status updates
      if (bookingClone.status === "inprogress") {
        form.value.actualStartTime = bookingClone.actualStartTime
          ? moment(bookingClone.actualStartTime).format("DD-MM-YYYY hh:mm a")
          : null;
        form.value.actualDuration = bookingClone.actualDuration || null;

        actualEndTime.value = form.value.actualDuration
          ? moment(form.value.actualStartTime, "DD-MM-YYYY hh:mm a", true)
              .add(form.value.actualDuration, "minutes")
              .format("DD-MM-YYYY hh:mm a")
          : null;
      }

      // Assign booking to pendingBookingId if its state is "Pending"
      if (bookingClone.state === "Pending") {
        pendingBookingId.value = bookingClone.id;
      }

      // Handle recurring booking data
      if (form.value.isRecurring && bookingClone.recurring) {
        recurring.value = {
          endDate: moment(bookingClone.recurring.endDate).format("DD-MM-YYYY"),
          frequency: bookingClone.recurring.frequency,
          interval: bookingClone.recurring.interval,
          days: recurringWeeks.value
            ?.filter((week) =>
              bookingClone.recurring.days
                ?.split(", ")
                ?.map((day: string) => day.trim())
                ?.includes(week.value)
            )
            .map((week) => week.id),
        };
      }

      // Assign additional metadata
      bookingCreatedDate.value = bookingClone.created;

      // Fetch necessary data after assignment
      getMaxActivityVisitors();
      assignRole();
    } else {
      // Reset form for new booking creation
      form.value = {
        id: null,
        date: new Date(),
        duration: 1,
        time: null,
        activity: null,
        guests: [],
        area: null,
        notes: "",
        members: [],
        owner: props.userId,
        agreement: haveCreateAccess.value ? true : false,
        notifyMe: false,
        isRecurring: false,
      };
      currentStep.value = 0;
      showPopup.value = false;
      pendingBookingId.value = null;
    }
  },
  { immediate: true }
);

/**
 * Watch function to handle changes in form fields related to activity, date,
 * actualDuration, and actualStartTime. It updates the form values, checks for
 * area compatibility, and calculates actualEndTime.
 *
 * @listens {[form.value.activity, form.value.date, form.value.actualDuration, form.value.actualStartTime]}
 *
 * @param {[string, Date, number, string]} [newActivityId, newDate, newDuration, actualStartTime] - The new values of the watched form fields.
 *
 * @emits {form.value.area} - Updates the area value if the new activity is not compatible with the current area.
 * @emits {form.value.time} - Updates the time value if the new date does not have any available time slots.
 * @emits {actualEndTime.value} - Calculates the new actualEndTime value based on the new actualStartTime and actualDuration.
 * @emits {getMaxActivityVisitors()} - Calls the getMaxActivityVisitors function when the new activity changes.
 */
watch(
  () => [
    form.value.activity,
    form.value.date,
    form.value.actualDuration,
    form.value.actualStartTime,
  ],
  ([newActivityId, newDate, newDuration, actualStartTime]) => {
    nextTick(() => {
      // Watch for activity changes
      if (newActivityId) {
        const area = props?.areaList?.find((el) => el.id === form.value.area);
        if (area && !area.activities.includes(newActivityId)) {
          form.value.area = null;
        }
      }

      // Watch for date changes
      // if (newDate && !timeList.value?.length) {
      //   form.value.time = null;
      // }

      // Watch for actualDuration and actualStartTime changes
      if (newDuration && actualStartTime) {
        actualEndTime.value = moment(
          actualStartTime,
          "DD-MM-YYYY hh:mm a",
          true
        )
          .add(newDuration, "minutes")
          .format("DD-MM-YY hh:mm a");
      }

      // Get maximum activity visitors when activity changes
      if (newActivityId) {
        getMaxActivityVisitors();
      }
    });
  }
);

/**
 * Watch function to handle changes in the globalErrorMessage.value.
 * It clears the error message after 2 seconds if it is visible.
 *
 * @listens {globalErrorMessage.value}
 *
 * @param {Object} newError - The new value of the globalErrorMessage.value.
 * @param {boolean} newError.isVisible - Indicates whether the error message is visible.
 *
 * @emits {handleUpdateErrorMessage(false, "", "", "")} - Calls the function to clear the error message.
 */
watch(
  () => globalErrorMessage.value,
  (newError) => {
    if (newError?.isVisible) {
      // clear after 2 sec
      setTimeout(() => {
        handleUpdateErrorMessage(false, "", "", "");
      }, 2000);
    }
  },
  { immediate: true }
);

/**
 * Watch function to handle changes in the isLeftHandWarning.value.
 * It checks if a left-handed warning should be displayed and updates the error message accordingly.
 *
 * @listens {isLeftHandWarning.value}
 *
 * @param {boolean} newValue - The new value of the isLeftHandWarning.value.
 *
 * @emits {handleUpdateErrorMessage(true, "A member is left handed but this bay cannot do left handed", "warning", "tabler-alert-triangle")}
 * - Calls the function to update the error message when a left-handed warning should be displayed.
 */
watch(
  () => isLeftHandWarning.value,
  (newValue) => {
    // red message is higher priority so check before show warning messages
    if (
      newValue &&
      bookingSteps.value?.length - 1 !== currentStep.value &&
      globalInfoMessage?.value?.color !== "error"
    ) {
      handleUpdateErrorMessage(
        true,
        "A member is left handed but this bay cannot do left handed",
        "warning",
        "tabler-alert-triangle"
      );
    }
  },
  { immediate: true }
);

watch(
  () => props?.isDrawerOpen,
  async (newValue) => {
    if (isEditBooking.value) {
      isBookingViewOnly.value = true;
    }
    if (newValue && props?.booking?.docketId) {
      // fetch docket data
      const docket = await pb
        .collection("dockets")
        .getOne(props?.booking?.docketId);

      discountCode.value = docket?.discountCode;
    }
    await getCurrentLocation();
  }
);

/**
 * Watch to handle drawer open/close, resetting form data,
 * and managing timers for inactivity.
 *
 * @listens {props.isDrawerOpen} - Listens to the drawer open/close state.
 * @listens {isEditBooking.value} - Listens to the edit booking state.
 * @listens {isPendingBooking.value} - Listens to the pending booking state.
 * @listens {showPopup.value} - Listens to the show popup state.
 * @listens {getAreaList.value} - Listens to the area list state.
 *
 * @emits {currentStep.value} - Emits the current step value.
 * @emits {clearPendingTimer()} - Emits the function to clear the pending timer.
 * @emits {isDeleteDialogVisible.value} - Emits the delete dialog visibility state.
 * @emits {isCancelDialogVisible.value} - Emits the cancel dialog visibility state.
 * @emits {isPendingBooking.value} - Emits the pending booking state.
 * @emits {docketId.value} - Emits the docket ID value.
 * @emits {docketLineId.value} - Emits the docket line ID value.
 * @emits {pendingBookingId.value} - Emits the pending booking ID value.
 * @emits {resetInactivityTimer()} - Emits the function to reset the inactivity timer.
 * @emits {startInactivityTimer()} - Emits the function to start the inactivity timer.
 * @emits {alternativeAreaList.value} - Emits the alternative area list value.
 */
watch(
  () => props?.isDrawerOpen,
  (newValue) => {
    if (!newValue) {
      currentStep.value = 0;
      clearPendingTimer();
      isDeleteDialogVisible.value = false;
      isCancelDialogVisible.value = false;
      isPendingBooking.value = false;
      docketId.value = "";
      docketLineId.value = "";
      pendingBookingId.value = null;
      isLeftHandedBookingErrorVerified.value = false;
      discountCode.value = "";
      discountCodeData.value = {};
      isBookingViewOnly.value = false;
    }
  },
  { immediate: true }
);

watchEffect(() => {
  if (!isEditBooking.value && isPendingBooking.value) {
    resetInactivityTimer();
    const resetEvents = ["mousemove", "keydown", "touchstart", "click"];
    resetEvents.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );
  }

  if (!showPopup.value) {
    clearInactivityTimer();
  } else {
    startInactivityTimer();
  }

  if (getAreaList.value) {
    alternativeAreaList.value = isLeftHandWarning?.value
      ? getAreaList.value?.filter((el) => el?.canLeftHanded)
      : getAreaList.value || [];
  }
});
</script>

<template>
  <VDialog
    :model-value="props.isDrawerOpen"
    temporary
    persistent
    no-click-animation
    location="end"
    width="900"
    min-height="500"
    class="scrollable-content booking_stepper"
  >
    <VOverlay
      v-model="isLoading"
      contained
      persistent
      class="align-center justify-center"
    >
      <VProgressCircular indeterminate />
    </VOverlay>

    <!-- Timeout error dialog -->
    <TimeOutError
      :is-visible="showPopup"
      @closeDialog="closeDialog"
      @continueBooking="continueBooking"
    />

    <!-- leftHandedWarning dialog -->
    <WarningDialog
      :is-visible="isLeftHandedWarningDialogVisible"
      @closeDialog="
        ($event) => {
          isLeftHandedWarningDialogVisible = $event;
        }
      "
      @verifiedByUser="
        () => {
          isLeftHandedBookingErrorVerified = true;
          isLeftHandedWarningDialogVisible = false;
        }
      "
    />
    <VCard height="auto" flat>
      <div
        class="d-flex align-center justify-space-between bg-dialogHeaderColor"
      >
        <!-- 👉 Header -->
        <VCardTitle>
          {{
            isBookingViewOnly
              ? `${bookingViewDetail.activity} Booking Details`
              : isEditBooking && !isBookingViewOnly
              ? "Update Booking"
              : "Add Booking"
          }}
        </VCardTitle>

        <VBtn
          icon="mdi-close"
          size="large"
          variant="text"
          class="rounded-0"
          color=""
          @click="closeDialog"
        ></VBtn>
      </div>

      <VCardSubtitle
        v-if="form.id && props?.booking?.status !== 'futureBooking'"
        class="pa-5 pb-0 font-weight-medium"
      >
        Options limited as booking
        {{ props?.booking?.status === "completed" ? "completed" : "started" }}
      </VCardSubtitle>

      <!-- Readonly Booking Data -->
      <VCardText v-if="isBookingViewOnly" class="text-center">
        <VDivider />
        <VContainer>
          <p class="text-h6 mb-1">Bay {{ bookingViewDetail.bay }}</p>
          <div class="text-h6 mb-1">
            {{ bookingViewDetail.startDate }} for
            {{ bookingViewDetail.duration - 5 }} Mins
          </div>
          <div class="text-h6 mb-1">
            Members : {{ bookingViewDetail.members?.join(",") }}
          </div>
          <div class="text-h6 mb-1">
            Visitors : {{ bookingViewDetail.visitors || "None" }}
          </div>
        </VContainer>
        <VDivider />
      </VCardText>
      <!-- Readonly Booking Data Ends -->

      <!-- Stepper Edit Content  -->
      <VCardText v-else>
        <VRow>
          <!-- large screen stepper -->
          <VCol cols="12" class="stepper-form-header">
            <CustomStepper
              v-model:current-step="currentStep"
              :items="bookingSteps"
              :is-active-step-valid="!isEditBooking ? false : undefined"
              class="stepper-icon-step-bg"
              direction="horizontal"
            />
          </VCol>
          <VCol cols="12" class="pt-0">
            <VWindow v-model="currentStep" class="disable-tab-transition">
              <VForm ref="dateTimeForRef">
                <VWindowItem class="mt-2">
                  <VRow class="pa-0">
                    <VCol
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      cols="12"
                      class="pa-0 pl-4 d-flex align-center"
                    >
                      <span class="booking_stepper__explanation"
                        >Choose the booking {{ getAreaSingular }}, Time, and
                        Duration.</span
                      >
                    </VCol>
                    <VCol
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      cols="12"
                      class="pa-0 pr-3"
                    >
                      <div
                        class="d-flex justify-end align-center float-right booking_stepper__recurring"
                      >
                        <VSwitch
                          v-model="form.isRecurring"
                          label="Make Recurring"
                        ></VSwitch>
                      </div>
                    </VCol>
                  </VRow>
                  <VRow>
                    <!-- 👉 Activity -->
                    <VCol lg="4" md="4" sm="6" xs="6" cols="12">
                      <VSelect
                        v-model="form.activity"
                        :items="props?.activityList"
                        :rules="[requiredValidator]"
                        :disabled="areaDurationDisable"
                        eager
                        item-title="name"
                        item-value="id"
                        label="Activity"
                        placeholder="Select Activity"
                      />
                    </VCol>
                    <!-- 👉 Area -->
                    <VCol lg="3" md="3" sm="6" xs="6" cols="6">
                      <VSelect
                        v-model="form.area"
                        :items="getAreaList"
                        :rules="[requiredValidator]"
                        :disabled="areaDurationDisable"
                        eager
                        item-title="areaNumber"
                        item-value="id"
                        :label="getAreaSingular"
                        :placeholder="`Select ${getAreaSingular}`"
                      />
                    </VCol>

                    <!-- 👉 Date -->
                    <VCol lg="5" md="5" sm="5" xs="6" cols="6">
                      <AppDateTimePicker
                        v-if="
                          form?.date &&
                          moment(form.date, 'DD-MM-YYYY').isSameOrAfter(
                            moment(),
                            'day'
                          )
                        "
                        v-model="form.date"
                        :config="datePickerConfig"
                        :disabled="isFieldsDisabled"
                        :rules="[requiredValidator]"
                        label="Date"
                        placeholder="Select Date"
                      />

                      <VTextField
                        v-else
                        v-model="form.date"
                        readonly
                        :rules="[requiredValidator]"
                        label="Date"
                        placeholder="Date Locked"
                      />
                    </VCol>

                    <!-- 👉 Start Time -->
                    <VCol lg="4" md="4" sm="3" cols="6">
                      <VSelect
                        v-model="form.time"
                        :items="timeList"
                        :rules="[requiredValidator]"
                        :disabled="isFieldsDisabled"
                        :menu-props="{ contentClass: 'select-menu-overlay' }"
                        item-title="label"
                        item-value="value"
                        label="Booked Time"
                        placeholder="Select Time"
                        class="dynamic_time_list"
                        @update:menu="handleMenuUpdate"
                      >
                        <template v-slot:item="{ props, item }">
                          <VListItem
                            v-bind="props"
                            :class="{
                              'is-normal-hour': item.raw.isNormalHour,
                              dark_theme: $vuetify.theme.current.dark,
                              light_theme: !$vuetify.theme.current.dark,
                              restricted_hours: !item.raw.isNormalHour,
                            }"
                            :style="{
                              backgroundColor: item.raw.color,
                              color: $vuetify.theme.current.dark ? '#fff' : '',
                            }"
                          >
                          </VListItem>
                        </template>
                      </VSelect>
                    </VCol>

                    <!-- 👉 Duration -->
                    <VCol lg="3" md="3" sm="4" xs="6" cols="6">
                      <VCombobox
                        v-model="form.duration"
                        :items="getDurationList"
                        :disabled="areaDurationDisable"
                        :rules="[requiredValidator]"
                        label="Duration"
                        placeholder="Select Duration"
                      />
                    </VCol>
                    <!-- have create access show them to set or update  -->
                    <VCol lg="5" md="5" sm="6" cols="12">
                      <VAutocomplete
                        v-model="form.owner"
                        :items="getMemberList"
                        :disabled="isFieldsDisabled"
                        chips
                        closable-chips
                        item-title="firstName"
                        item-value="id"
                        color="blue-grey-lighten-2"
                        label="Owner"
                        class="member_list_selection"
                        @click.prevent="
                          () => {
                            if (!props?.memberList?.length) {
                              emit('loadUsersList', true);
                            }
                          }
                        "
                      >
                        <template v-slot:chip="{ props, item }">
                          <UserChips
                            :bind="props"
                            :item="item"
                            :baseUrl="pb.baseUrl"
                            @removeUser="form.owner = []"
                          />
                        </template>

                        <template v-slot:item="{ props, item }">
                          <UserListItem
                            :bind="props"
                            :item="item"
                            :baseUrl="pb.baseUrl"
                          />
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span>
                            {{
                              item.raw.firstName && item.raw.lastName
                                ? `${item.raw.firstName} ${item.raw.lastName}`
                                : item.raw.username
                            }}
                          </span>
                        </template>
                      </VAutocomplete>
                    </VCol>
                    <VCol
                      v-if="
                        (haveEditAccess || haveCreateAccess) &&
                        isInProgressBooking
                      "
                    >
                      <VRow>
                        <VCol
                          v-if="isInProgressBooking"
                          lg="4"
                          md="4"
                          sm="6"
                          cols="12"
                        >
                          <!-- actual start time -->
                          <VTextField
                            v-model="form.actualStartTime"
                            readonly
                            label="Start time"
                          />
                        </VCol>
                        <VCol
                          v-if="isInProgressBooking"
                          lg="4"
                          md="4"
                          sm="6"
                          cols="12"
                        >
                          <!-- actual end time -->
                          <VTextField
                            v-model="actualEndTime"
                            readonly
                            label="End time"
                          />
                        </VCol>
                        <VCol
                          v-if="isInProgressBooking"
                          lg="4"
                          md="4"
                          sm="6"
                          cols="12"
                        >
                          <!-- end time -->
                          <VSelect
                            v-model="form.actualDuration"
                            :items="durationExtendList"
                            :readonly="!haveCreateAccess || !haveEditAccess"
                            label="Actual duration"
                          />
                        </VCol>
                      </VRow>
                    </VCol>
                  </VRow>
                </VWindowItem>
              </VForm>
              <VForm ref="memberVisitorsFormRef">
                <VWindowItem>
                  <div class="booking_stepper__explanation mb-6">
                    Select the Players
                  </div>
                  <VRow>
                    <VCol lg="6" md="6" sm="6" xs="6" cols="12">
                      <VAutocomplete
                        v-model="form.members"
                        :items="getMemberList"
                        :disabled="isFieldsDisabled"
                        chips
                        closable-chips
                        multiple
                        item-title="firstName"
                        item-value="id"
                        color="blue-grey-lighten-2"
                        label="Members"
                        class="member_list_selection"
                        @update:modelValue="handleMemberUpdate"
                        @click.prevent="
                          () => {
                            if (!props?.memberList?.length) {
                              emit('loadUsersList', true);
                            }
                          }
                        "
                      >
                        <template v-slot:chip="{ props, item }">
                          <UserChips
                            :bind="props"
                            :item="item"
                            :baseUrl="pb.baseUrl"
                            @click="(event: any) => {
                            if (haveCreateAccess) {
                              event.stopPropagation();
                              router.push(`user/${item?.raw?.id}`);
                            }
                          }
                            "
                          />
                        </template>

                        <template v-slot:item="{ props, item }">
                          <UserListItem
                            :bind="props"
                            :item="item"
                            :baseUrl="pb.baseUrl"
                          />
                        </template>
                      </VAutocomplete>
                    </VCol>

                    <!-- 👉 Guests -->
                    <VCol lg="6" md="6" sm="6" xs="6" cols="12">
                      <VCombobox
                        v-model="form.guests"
                        :items="[]"
                        :disabled="isFieldsDisabled"
                        multiple
                        chips
                        closable-chips
                        label="Guests"
                        placeholder="Enter guests"
                        @update:modelValue="handleGuestUpdate"
                      >
                        <template v-slot:selection="data">
                          <VChip
                            v-bind="data.attrs"
                            :key="JSON.stringify(data.item)"
                            :disabled="data.disabled"
                            :model-value="data.selected"
                            size="small"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            <template v-slot:prepend>
                              <VAvatar class="bg-accent text-uppercase" start>{{
                                data.item.title.slice(0, 1)
                              }}</VAvatar>
                            </template>
                            {{ data.item.title }}
                          </VChip>
                        </template>
                      </VCombobox>
                    </VCol>
                    <!-- 👉 Description -->
                    <VCol cols="12">
                      <VTextarea
                        v-model="form.notes"
                        :disabled="isFieldsDisabled"
                        label="Notes"
                        placeholder="Booking Notes"
                      />
                    </VCol>
                  </VRow>
                </VWindowItem>
              </VForm>
              <VForm ref="refRecurringVForm">
                <VWindowItem v-if="bookingSteps?.some((el) => el.id === 3)">
                  <div class="mb-6">Select the Recurring configuration</div>
                  <VRow>
                    <VCol lg="3" md="3" sm="3" xs="6" cols="12">
                      <VTextField
                        v-model="recurring.frequency"
                        :disabled="isFieldsDisabled"
                        :rules="[requiredValidator]"
                        label="Repeat"
                      />
                    </VCol>

                    <!-- 👉 Interval -->
                    <VCol lg="4" md="4" sm="3" xs="6" cols="12">
                      <VSelect
                        v-model="recurring.interval"
                        :items="['Weeks', 'Months']"
                        :disabled="isFieldsDisabled"
                        :rules="[requiredValidator]"
                        label="Interval"
                        @update:modelValue="recurring.days = []"
                      >
                      </VSelect>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <VBtn
                        v-for="item in recurringWeeks"
                        icon=""
                        :key="item.id"
                        :color="
                          recurring?.days?.includes(item?.id)
                            ? 'primary'
                            : 'grey-400'
                        "
                        size="small"
                        class="ml-1"
                        @click="selectRecurringItem(item)"
                      >
                        {{ item.label }}
                        <VTooltip
                          activator="parent"
                          location="top"
                          open-delay="500"
                        >
                          {{ item?.value }}
                        </VTooltip>
                      </VBtn>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol lg="5" md="5" sm="5" xs="6" cols="6">
                      <AppDateTimePicker
                        v-if="
                          !recurring.endDate ||
                          moment(recurring.endDate, 'DD-MM-YYYY').isSameOrAfter(
                            moment(),
                            'day'
                          )
                        "
                        v-model="recurring.endDate"
                        :key="getMaxBookAhead"
                        :config="recurringEndDateConfig(getMaxBookAhead)"
                        :disabled="isFieldsDisabled"
                        :rules="[requiredValidator]"
                        append-icon="mdi-date"
                        label="ENDS ON"
                        placeholder="ENDS ON"
                      />

                      <VTextField
                        v-else
                        v-model="recurring.endDate"
                        readonly
                        :rules="[requiredValidator]"
                        append-icon="mdi-date"
                        label="ENDS ON"
                        placeholder="ENDS ON"
                      />
                    </VCol>
                  </VRow>
                </VWindowItem>
              </VForm>
              <VForm ref="agreementFormRef">
                <VWindowItem v-if="bookingSteps?.some((el) => el.id === 4)">
                  <div class="booking_stepper__explanation mb-6">
                    Review and agree to the terms and conditions
                  </div>
                  <VCheckbox v-model="form.agreement">
                    <template #label>
                      <div class="booking_stepper__agreement-message">
                        {{ getAgreementMessage }}
                      </div>
                    </template>
                  </VCheckbox>
                  <VCheckbox v-model="form.notifyMe">
                    <template #label>
                      <div class="booking_stepper__agreement-message">
                        {{ "Notify me 1 hour before the booking by email" }}
                      </div>
                    </template>
                  </VCheckbox>
                </VWindowItem>
              </VForm>
              <VForm ref="fourthFormRef">
                <VWindowItem>
                  <div class="">
                    <PaymentForm
                      :have-create-permission="haveCreateAccess"
                      :logged-in-user-id="userId"
                      :total-due="props?.booking.due"
                      :total-paid="props?.booking?.paid"
                      :booking-id="props?.booking.id"
                      :coupon-value="props?.couponValue"
                      :location="selectedActivity?.location"
                      :discountProduct="props?.membership?.discountProduct || 0"
                      :discountBookings="
                        props?.membership?.discountFurtherBookings ||
                        getBookingDiscount ||
                        0
                      "
                      :discountEvents="props?.membership?.discountEvents || 0"
                      :discountSources="
                        props?.membership?.discountFurtherBookings
                          ? ['Membership']
                          : ['DiscountCode']
                      "
                      :owner="form?.owner"
                      :booking-amount="props?.booking?.value"
                      :bookingSlot="{
                        sessionPrice: selectedActivity?.sessionFullPrice,
                        slotQty: selectedDurationSlot,
                      }"
                      :payMethods="props?.booking?.payMethod"
                      type="Booking"
                      @paymentSuccess="handlePaymentSuccess"
                      @goToPosPayment="gotToPos"
                      @updateMessage="
                        handleUpdateErrorMessage(
                          $event?.isVisible,
                          $event?.message,
                          $event?.color,
                          $event?.icon
                        )
                      "
                      @refetch="refetchBooking"
                      @addNewCardDialog="emit('addNewCardDialog', true)"
                      @closeDialog="emit('closeDialog', false)"
                      @closeBooking="closeDialog"
                    />
                  </div>
                </VWindowItem>
              </VForm>
            </VWindow>
          </VCol>
        </VRow>
      </VCardText>

      <!-- Stepper Edit Content Ends -->
      <div class="bold_tag">
        <div class="d-flex flex-column gap-2 pb-4">
          <div class="pl-5" v-if="isInCurrentTimeSlot">
            Some Details can only be changed by staff as booking in current time
            slot
          </div>
          <div class="pl-5" v-if="isOutHourWarningVisible">
            Some details can only be changed by staff as booked for out of hours
          </div>
        </div>
        <VAlert
          v-if="globalErrorMessage?.isVisible"
          :color="globalErrorMessage?.color || 'error'"
          :icon="globalErrorMessage?.icon || 'tabler-xbox-x'"
        >
          {{ globalErrorMessage?.message }}
        </VAlert>
        <VAlert
          v-else
          :color="globalInfoMessage.color"
          :icon="globalInfoMessage?.icon || 'mdi-info'"
        >
          {{ globalInfoMessage?.message }}
        </VAlert>
      </div>
      <VCardText class="button_container">
        <div class="d-flex button_group">
          <div class="d-flex button_group__first_btn">
            <VBtn
              v-if="isBookingViewOnly"
              color="secondary"
              variant="flat"
              @click.prevent="isBookingViewOnly = false"
            >
              Edit Booking
            </VBtn>

            <VBtn
              v-else
              :disabled="currentStep === 0"
              color="secondary"
              variant="flat"
              @click.prevent="currentStep--"
            >
              <VIcon icon="mdi-arrow-left" start class="flip-in-rtl" />
              Previous
            </VBtn>

            <div class="booking_dynamic_buttons__large">
              <VBtn
                v-if="isEditBooking"
                color="success"
                variant="flat"
                @click.prevent="printTicketIsVisible = true"
                class="ml-2"
              >
                <VIcon icon="mdi-printer" start class="flip-in-rtl" />
                Print
              </VBtn>
            </div>
            <!-- QR -->
            <div
              v-if="props?.booking?.id"
              class="booking_dynamic_buttons__large"
            >
              <IconBtn
                color="grey"
                icon="mdi-qrcode"
                class="rounded-sm ml-2 border-md"
                border="secondary lg"
                @click.stop="
                  () => {
                    selectedBookingId = props?.booking?.id;
                    isQrCodeVisible = true;
                  }
                "
              />
            </div>
          </div>
          <div class="d-flex">
            <!-- for large screen alignment button in between  -->
            <div class="booking_dynamic_buttons__large">
              <!-- <div class="button_group__multi_btn">
                <VBtn
                  v-if="isEditBooking"
                  :disabled="isStartButtonDisabled"
                  color="primary"
                  variant="outlined"
                  @click="handleStartBooking"
                >
                  Start
                </VBtn>
              </div> -->
              <div
                v-if="
                  isEditBooking &&
                  bookingStatus !== 'completed' &&
                  !isBookingViewOnly
                "
                class="button_group__multi_btn"
              >
                <VBtn color="error" @click="isDeleteDialogVisible = true">
                  Delete
                </VBtn>
              </div>
            </div>
            <VBtn
              variant="flat"
              color="secondary"
              class="ml-2 booking_dynamic_buttons__large"
              @click="closeDialog"
            >
              Close
            </VBtn>

            <!-- Edit booking -->
            <div
              v-if="isEditBooking"
              class="button_group__multi_btn align-content-center"
            >
              <VBtn
                v-if="
                  bookingSteps.length - 1 !== currentStep && !isBookingViewOnly
                "
                :disable="isActionButtonDisabled"
                color="success"
                @click="handleSubmit"
              >
                {{ isEditBooking ? "Save" : "Confirm" }}
              </VBtn>
              <VBtn
                v-if="isYetToPayDues && isBookingViewOnly"
                color="success"
                class="ml-2"
                @click="handlePaymentDues"
              >
                Pay
              </VBtn>
            </div>

            <!-- create booking -->
            <div v-else class="align-content-center">
              <VBtn
                v-if="bookingSteps.length - 2 > currentStep"
                class="ml-2"
                @click="goToNextStep"
              >
                Next
                <VIcon icon="mdi-arrow-right" end class="flip-in-rtl" />
              </VBtn>
              <VBtn
                v-else
                :disable="isActionButtonDisabled"
                color="success"
                class="ml-2"
                @click="handleSubmit"
              >
                {{ isEditBooking ? "Save" : "Confirm" }}
              </VBtn>
            </div>
          </div>
        </div>

        <!-- small screen alignment button at end -->
        <div
          v-if="isEditBooking"
          class="d-flex button_group booking_dynamic_buttons__small align-center"
        >
          <div class="button_group__first_btn">
            <!-- <VBtn
              :disabled="isStartButtonDisabled"
              color="primary"
              variant="outlined"
              @click="handleStartBooking"
            >
              Start
            </VBtn> -->
            <VBtn variant="flat" color="secondary" @click="closeDialog">
              Closemmm
            </VBtn>
          </div>

          <div>
            <VBtn
              v-if="isEditBooking"
              color="success"
              variant="flat"
              @click.prevent="printTicketIsVisible = true"
              class="ml-2"
            >
              <VIcon icon="mdi-printer" start class="flip-in-rtl" />
              Print
            </VBtn>
          </div>

          <div v-if="bookingStatus !== 'completed'">
            <VBtn
              color="error"
              class="ml-2"
              @click="isDeleteDialogVisible = true"
            >
              Delete
            </VBtn>
          </div>
        </div>
      </VCardText>
    </VCard>

    <!-- Delete Booking confirm dialog-->
    <BookingDeleteConfirmDialog
      :is-open="isDeleteDialogVisible"
      :is-admin="haveCreateAccess"
      :activity="activityList?.find((activity: any) => activity.id === props?.booking?.activity)"
      :is-recurring="props?.recurringDetails?.bookings?.length ? true : false"
      @closeDialog="isDeleteDialogVisible = $event"
      @cancelBooking="checkDelete"
    />

    <!-- Booking cancel confirmation dialog -->
    <BookingCancelConfirmDialog
      :is-open="isCancelDialogVisible"
      :activity="activityList?.find((activity: any) => activity.id === props?.booking?.activity)"
      @closeDialog="isCancelDialogVisible = $event"
      @cancelBooking="confirmCloseBooking()"
    />

    <!-- Error date list dialog -->
    <ErrorWarnDatesDialog
      :is-open="isBookingWarningDialogVisible"
      :dates="errorDateList"
      @closeDialog="isBookingWarningDialogVisible = false"
    />
  </VDialog>

  <TicketPrint
    :is-visible="printTicketIsVisible"
    :emailed="props?.booking?.emailed"
    :printed="props?.booking?.printed"
    :have-edit-access="haveEditAccess"
    @print="
      async ($event: any) => {
        await handleTicketPrint($event, props?.booking?.id);
        emit('refetchBookingData', props?.booking?.id);
        printTicketIsVisible = false;
      }
    "
    @closeDialog="printTicketIsVisible = false"
  />

  <QrCode
    :is-visible="isQrCodeVisible"
    :qr-value="selectedBookingId"
    @closeDialog="isQrCodeVisible = false"
  />
</template>

<style scoped lang="scss">
.v-slide-group__content {
  padding: 0.625rem;
}

.stepper-icon-step {
  margin-block-start: 0.625rem;
}

.stepper-icon {
  border-radius: 0.3125rem;
  background-color: rgb(var(--v-theme-primary));
  color: rgba(var(--v-theme-on-primary));
}

.stepper-form-header {
  padding: 1.25rem;
  font-size: 0.625rem;
}

.member_list_selection {
  .v-field__input {
    max-block-size: 7.5rem;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}

.button_group {
  display: flex;
  justify-content: space-between;

  &__multi_btn {
    padding-inline-start: 0.625rem !important;
  }
}

.booking_dynamic_buttons {
  &__large {
    display: flex;
  }

  &__small {
    display: none !important;
  }
}

@media (max-width: 720px) {
  .button_group {
    &__first_btn {
      padding: 8px;
    }
  }

  .booking_dynamic_buttons {
    &__large {
      display: none;
    }

    &__small {
      display: flex !important;
    }
  }
}
</style>
