import navItems from "@/navigation/vertical";
import { defineStore } from "pinia";
import { usePermission } from "@/utils/usePermission";
import { pb, RoleRecordModel } from "@/utils/PocketBaseAdapter";
import { useUserModeChange } from "@/store/useUserModeChange";
import isEmpty from "lodash/isEmpty";
import type { permissionList } from "@/@layouts/types";
import type { UserRecordModel } from "@/utils/PocketBaseAdapter";
import { useUserStore } from "./UserStore";

export const useUserRole = defineStore("useUserRoles", () => {
  // Interface
  interface permissionMap {
    [key: string]: permissionList;
  }

  // store
  const usePermissions = usePermission();
  const userMode = useUserModeChange();
  const { isUserMode } = storeToRefs(userMode);
  const userStore = useUserStore();
  const { currentLocationDetails } = storeToRefs(userStore);

  // Composable
  const { isDisable } = usePermissions;

  // Data
  const permissionListMap = ref<permissionMap>({});
  const currentLocation = ref<any>(
    localStorage.getItem("homeLocation") ||
      (pb.authStore.model as UserRecordModel)?.homeLocation
  );
  const rolesList = ref<any[]>([]);
  const rolesMap = ref<any>(new Map<String, RoleRecordModel>());
  const userInfo = ref<any>({});
  const accessReadersList = ref<any[]>([]);
  const barCodeReadersList = ref<any[]>([]);
  const userId = ref(pb?.authStore?.model?.id || null);
  const doorBarCodeReader = ref(
    localStorage.getItem("doorbarcodeReader") || ""
  );

  // Define the apps object
  const apps = ref<any>({
    POS: {
      isVisible: false,
      POSName: "",
      accessReader: [],
      terminal: "",
      barcodeReader: "",
    },
    Dockets: {
      isVisible: false,
    },
    Kiosk: {
      isVisible: false,
    },
    "Staff TimeClock": {
      isVisible: false,
    },
  });

  // Function to initialize the apps ref with data from localStorage
  const initializeApps = () => {
    try {
      // Get data from localStorage
      const storedApps = localStorage.getItem("Apps");

      // If data exists, parse it and update the ref
      if (storedApps) {
        const parsedApps = JSON.parse(storedApps);
        apps.value = parsedApps;
      }
    } catch (error) {
      console.error("Error initializing apps from localStorage: ", error);
    }
  };

  const isUserLoggedIn = computed(() => !!userId.value);

  /**
   * Computes and returns the names of access readers associated with the POS app.
   *
   * @remarks
   * This function filters the access readers based on their IDs included in the POS app's access reader list.
   * It then returns the names of the filtered readers in a formatted string.
   * If there are no readers, an empty string is returned.
   * If there are exactly two readers, their names are concatenated with "or".
   * Otherwise, the names of all readers are joined with commas.
   *
   * @returns {string} - The formatted string of access reader names.
   */
  const getAccessReaderName = computed(() => {
    const readers =
      accessReadersList.value?.reduce<string[]>(
        (acc, reader) =>
          apps.value?.POS?.accessReader?.includes(reader.id)
            ? [...acc, `<span class="fob-badge">${reader.name}</span>`] // Use a span for badge styling
            : acc,
        []
      ) || [];

    if (!readers.length) return "";
    if (readers.length === 2)
      return `${readers[0]} <span class="separator">or</span> ${readers[1]}`;

    return readers.join('<span class="separator">, </span>'); // Join with a separator for multiple names
  });

  /**
   * Computes and returns the name of the barcode reader associated with the POS app.
   *
   * @remarks
   * This function uses the `barCodeReadersList` and `apps` reactive refs to find the name of the barcode reader
   * associated with the POS app based on their IDs. If a matching reader is found, its name is returned.
   * If no matching reader is found or if either of the reactive refs is not defined, an empty string is returned.
   *
   * @returns {string} - The name of the barcode reader associated with the POS app, or an empty string if no matching reader is found.
   */
  const getBarcodeReaderName = computed(() => {
    return (
      barCodeReadersList.value?.find(
        (reader: any) => reader?.id === apps.value?.POS?.barcodeReader
      )?.name || ""
    );
  });

  /**
   * Computes and aggregates user permissions based on their roles and location.
   *
   * @remarks
   * This function filters relevant roles based on the user's roles and location,
   * then aggregates permissions from these roles. It resolves conflicts by prioritizing
   * permissions based on a predefined order.
   *
   * @returns {Object} - An object representing aggregated permissions, where keys are permission keys
   * and values are either a single permission level or an array of permission levels.
   */
  const permission = computed(() => {
    const permissionPriority = ["Create", "Edit", "View", "Assign", "None"];
    const relevantRoles = !isUserMode.value
      ? rolesList.value?.filter(
          (role) =>
            userInfo.value?.roles?.includes(role.id) &&
            role.location === currentLocation.value
        )
      : [];
    const aggregatedPermissions = relevantRoles?.reduce((acc, role) => {
      Object.keys(role).forEach((permissionKey) => {
        if (!acc[permissionKey]) {
          acc[permissionKey] = new Set();
        }
        acc[permissionKey].add(role[permissionKey]);
      });
      return acc;
    }, {});

    Object.keys(aggregatedPermissions)?.forEach((permissionKey) => {
      const permissionsMap = Array.from(aggregatedPermissions[permissionKey]);
      if (permissionsMap.some((el: any) => permissionPriority?.includes(el))) {
        aggregatedPermissions[permissionKey] =
          permissionPriority?.find((permission) =>
            permissionsMap?.includes(permission)
          ) || "None";
      } else {
        aggregatedPermissions[permissionKey] = permissionsMap;
      }
    });

    return aggregatedPermissions;
  });

  /**
   * Computed property to generate the list of navigation items based on user permissions.
   *
   * @returns {Array} - An array of navigation items with their 'disable' property set based on user permissions.
   */
  const navListItems = computed(() => {
    // Determine if the "Apps" heading should be visible based on the visibility of its sub-links
    const isAppsHeadingVisible = Object.values(apps.value).some(
      (app: any) => app?.isVisible
    );

    // Cached app visibility for use within the reduce function
    const appNavItems: any = {
      Dockets: apps.value.Dockets?.isVisible,
      POS: apps.value.POS?.isVisible,
      Kiosk: apps.value.Kiosk?.isVisible,
      "Staff TimeClock": apps.value["Staff TimeClock"]?.isVisible,
    };

    let hasWebShopAdded = false;

    return navItems?.reduce((acc: any[], el: any) => {
      const disable = el.disable || isDisable(el, permission.value);

      // Specific check for "WebShop" visibility
      if (
        el.title === "WebShop" &&
        !currentLocationDetails.value?.onlineShopping &&
        !hasWebShopAdded
      ) {
        acc.push({ ...el, disable: true });
        hasWebShopAdded = true;
        return acc;
      }

      // Check if user is not logged in
      if (!isUserLoggedIn.value) {
        // Only include items where isAuthRequired is false
        if (el.isAuthRequired === false) {
          acc.push({ ...el, disable });
        }
        return acc; // Skip further checks if not logged in
      }

      // Skip items where isAuthRequired is false if user is logged in
      if (isUserLoggedIn.value && el.isAuthRequired === false) {
        return acc;
      }

      // Handle "Apps" heading visibility
      if (el.heading === "Apps" && isUserMode.value) {
        if (isAppsHeadingVisible) acc.push({ ...el, disable });
        return acc;
      }

      // Check permissions if required
      const hasPermission =
        !el.permissionRequired ||
        permission.value?.[el.permissionRequired]?.includes(true);

      // Add the item if it is visible and the user has permission
      if (hasPermission && (!el.isSetToVisible || appNavItems[el.title])) {
        acc.push({ ...el, disable });
      }

      return acc;
    }, []);
  });

  /**
   * Generates a permission list based on the user's role and location.
   *
   * @remarks
   * This function is responsible for transforming the user's role permissions into a more detailed and usable format.
   * It iterates through the user's role permissions, applies a predefined set of permissions for each role permission,
   * and stores the result in the `permissionListMap` reactive ref.
   *
   * @returns {void}
   */
  const generatePermission = (): void => {
    const getPermissionsForRole = (currentPermission: any) => {
      switch (currentPermission) {
        case "Create":
          return ["Create", "Edit", "View"];
        case "Edit":
          return ["Edit", "View", "None"];
        case "View":
          return ["View", "None"];
        default:
          return ["None"];
      }
    };

    if (!isEmpty(permission.value)) {
      const permissionList = {};
      for (let per in permission.value) {
        permissionList[per] = getPermissionsForRole(permission.value[per]);
      }
      permissionListMap.value = permissionList;
    } else {
      permissionListMap.value = {};
    }
  };

  const getUserRolePermission = async (inList?: boolean) => {
    try {
      if (!rolesList.value.length || inList) {
        const recordList = await pb
          .collection("roles")
          .getFullList<RoleRecordModel>();
        rolesList.value = recordList;
        recordList.forEach((role: any) => rolesMap.value.set(role.id, role));
      }

      if (isEmpty(userInfo.value)) {
        const userId = pb.authStore.model?.id;

        const record = await pb
          .collection("users")
          .getOne(userId, { requestKey: "userprofile", expand: "membership" });
        userInfo.value = record;
      }
    } catch (e) {}
  };

  const getAccessReaderList = async () => {
    try {
      const accessReaderList = await pb
        .collection("accessReaders")
        .getFullList({
          expand: "id,address,name,door,door,lastFOBNum",
        });
      accessReadersList.value = accessReaderList;
    } catch (e) {}
  };

  const getBarCodeReaderList = async () => {
    try {
      const readerList = await pb.collection("barcodeReaders").getFullList();
      barCodeReadersList.value = readerList;
    } catch (e) {}
  };

  // reset rolesList and rolesMap to clear permission on logout
  const $reset = () => {
    rolesList.value = [];
    userInfo.value = {};
    rolesMap.value.clear();
    permissionListMap.value = {};
  };

  onMounted(async () => {
    await getUserRolePermission();
    initializeApps();
  });

  watchEffect(() => {
    if (permission.value) {
      generatePermission();
    }
  });

  // Run immediately on load to sync with initial value
  watch(
    () => pb.authStore?.model?.id,
    (newId) => {
      if (newId) {
        userId.value = newId;
      }
    },
    { immediate: true, deep: true }
  );

  return {
    doorBarCodeReader,
    permission,
    permissionListMap,
    navListItems,
    userInfo,
    currentLocation,
    rolesList,
    rolesMap,
    apps,
    accessReadersList,
    barCodeReadersList,
    getAccessReaderName,
    getBarcodeReaderName,
    userId,
    getBarCodeReaderList,
    $reset,
    generatePermission,
    getUserRolePermission,
    getAccessReaderList,
  };
});
