import type { DocketRecordModel } from "@/utils/PocketBaseAdapter";
import { pb } from "@/utils/PocketBaseAdapter";
import { useProductStore } from "@/utils/ProductStore";
import { useUserStore } from "@/utils/UserStore";
import { useListStore } from "@/store/listStore";
import { cloneDeep } from "lodash";
import { RecordModel } from "pocketbase";
import { toast } from "vue3-toastify";
import { useCheckoutStore } from "./Docket";
import { useDocketStore } from "./DocketStore";
import { useDiscountCode } from "@/store/useDiscountCode";
import isEmpty from "lodash/isEmpty";

export const useDocket = () => {
  interface CheckoutStore {
    processingItems: {
      inProcessing: any;
      processing: number;
      id: any;
      qty: number;
      lineTotal: number;
      itemPrice: number;
    }[];
    docketLinesItems: {
      isAvailable: any;
      id: any;
      qty: number;
      lineTotal: number;
      itemPrice: number;
    }[];
  }

  interface SelectedItem {
    inProcessing: number;
    id: any;
    qty: number;
    lineTotal: number;
    itemPrice: number;
    processing: number;
  }

  // Store
  const checkoutStore = useCheckoutStore();
  const productStore = useProductStore();
  const userStore = useUserStore();
  const useList = useListStore();
  const docketStore = useDocketStore();
  const discount = useDiscountCode();

  // composable
  const { categories } = storeToRefs(productStore);
  const { docketDiscountData } = storeToRefs(docketStore);
  const {
    originalDocketItems,
    docketPaymentId,
    docketLinesItems,
    processingItems,
    discountSources,
  } = storeToRefs(checkoutStore);
  const { fetchDiscountPercentage, paymentApproved } = docketStore;
  const { userId } = storeToRefs(userStore);
  const { productsMap } = storeToRefs(useList);
  const { discountCode, discountCodeData } = storeToRefs(discount);

  const route = useRoute();

  // Data
  const docketId = ref<string | any>("");
  const email = ref<string | null>("");
  const isInitialized = ref<boolean>(false);
  const DISCOUNT_FIELDS = {
    PRODUCT: "discountProducts",
    BOOKING: "discountBookings",
    EVENT: "discountEvents",
  } as const;

  // Methods
  const PayWithTerminal = async (terminal: any) => {
    // the Terminal Payment button populates the docketPayments as pending, server will process payment and update this to approved
    const data = {
      docket: docketId.value,
      paymentType: "Terminal",
      amount: Number(checkoutStore.payingNow).toFixed(2),
      cardTransactionReff: "Pending",
      status: "Pending",
      createdBy: userId.value,
      paymentTerminal: terminal?.id,
    };

    const record = await pb.collection("docketPayments").create(data);

    docketPaymentId.value = record.id;
    checkoutStore.paymentStatus = {
      type: record.status,
      message: "",
    };
    // update processing key in docketLine
    await updateItemStatus("Processing");
  };

  const PayWithUserAccount = async (user: string) => {
    // the Terminal Payment button populates the docketPayments as pending, server will process payment and update this to approved
    const data = {
      docket: docketId.value,
      paymentType: "User Account",
      amount: Number(checkoutStore.payingNow).toFixed(2),
      cardTransactionReff: "Pending",
      user: user,
      status: "Pending",
      createdBy: userId.value,
    };

    const record = await pb.collection("docketPayments").create(data);

    docketPaymentId.value = record.id;
    checkoutStore.paymentStatus = {
      type: record.status,
      message: "",
    };
    // update processing key in docketLine
    await updateItemStatus("Processing");
  };

  // update processed key in docketLines
  const updateProcessedStatus = async () => {
    try {
      let records: any[] = [];
      if (!records.length) {
        let filter = `docket="${docketId.value}"`;
        const response = await pb.collection("docketLines").getFullList({
          filter: filter,
        });
        if (response) {
          records = [...response];
        }
      } else {
        records = [...checkoutStore.processingItems];
      }
      records.map(async (item) => {
        // update processed key as number of quantity of item as its processed payment
        item.processed = item.processing;
        item.updatedBy = userId.value;
        await pb.collection("docketLines").update(item.id, item);
      });
    } catch (e) {}
  };

  // Payment with cash
  const PayWithCash = async (amtTender: any) => {
    const data = {
      docket: docketId.value,
      paymentType: "Cash",
      amount: Number(checkoutStore.payingNow).toFixed(2),
      cardTransactionReff: "Pending",
      status: "Approved",
      amtTendered: amtTender,
      createdBy: userId.value,
    };

    const record = await pb.collection("docketPayments").create(data);

    docketPaymentId.value = record.id;
    if (record && record?.status == "Approved") {
      const status =
        checkoutStore.balanceToPay - checkoutStore.payingNow <= 0
          ? "Paid"
          : "Partial";
      const due =
        checkoutStore.balanceToPay - checkoutStore.payingNow <= 0
          ? 0
          : Number(
              checkoutStore.balanceToPay - checkoutStore.payingNow
            ).toFixed(2);

      await pb.collection("dockets").update<DocketRecordModel>(docketId.value, {
        status,
        due: due,
        updatedBy: userId.value,
      });
      // Payment is done for the items the docketLines status will be processed
      await paymentApproved(docketId.value);
    }
    checkoutStore.amtTender = 0;
  };

  // Pay with invoice
  const payWithInvoice = async (params: {
    paymentType: string;
    cardTransactionReff: string;
    status: string;
    amtTendered: number | string;
    notes: string;
  }) => {
    try {
      const data = {
        docket: docketId.value,
        paymentType: params.paymentType,
        amount: Number(checkoutStore.payingNow).toFixed(2),
        cardTransactionReff: params.cardTransactionReff,
        status: params.status,
        amtTendered: params?.amtTendered,
        notes: params.notes,
        createdBy: userId.value,
      };

      const record = await pb.collection("docketPayments").create(data);
      if (record) {
        docketPaymentId.value = record.id;

        const status =
          checkoutStore.balanceToPay - checkoutStore.payingNow <= 0
            ? "Paid"
            : "Partial";

        const due =
          checkoutStore.balanceToPay - checkoutStore.payingNow <= 0
            ? 0
            : Number(
                checkoutStore.balanceToPay - checkoutStore.payingNow
              ).toFixed(2);

        await pb
          .collection("dockets")
          .update<DocketRecordModel>(docketId.value, {
            status,
            due,
            updatedBy: userId.value,
          });

        // update processing key in docketLine
        await paymentApproved(docketId.value);
      }
    } catch (e) {}
  };

  // Pay with Manual Terminal
  const payWithManualTerm = async (params: {
    paymentType: string;
    cardTransactionReff: string;
    status: string;
    amtTendered: number | string;
    description: string;
    paymentTerminal: string;
    paymentId: any;
  }) => {
    try {
      const data = {
        docket: docketId.value,
        paymentType: params.paymentType,
        amount: Number(checkoutStore.payingNow).toFixed(2),
        cardTransactionReff: params.cardTransactionReff,
        status: params.status,
        amtTendered: params?.amtTendered,
        paymentTerminal: params?.paymentTerminal,
        createdBy: userId.value,
      };
      let record;
      if (params.paymentId) {
        data.updatedBy = userId.value;
        // Update existing payment if paymentId exists
        record = await pb
          .collection("docketPayments")
          .update(params.paymentId, data);
      } else {
        // Create a new payment if paymentId does not exist
        record = await pb.collection("docketPayments").create(data);
      }
      if (record) {
        docketPaymentId.value = record.id;
        const status =
          checkoutStore.balanceToPay - checkoutStore.payingNow <= 0
            ? "Paid"
            : "Partial";
        const due =
          checkoutStore.balanceToPay - checkoutStore.payingNow <= 0
            ? 0
            : Number(
                checkoutStore.balanceToPay - checkoutStore.payingNow
              ).toFixed(2);

        await pb
          .collection("dockets")
          .update<DocketRecordModel>(docketId.value, {
            status,
            due: due,
            updatedBy: userId.value,
            description: params.description,
          });
        if (params.status == "Approved") {
          await paymentApproved(docketId.value);
        }
      }
    } catch (e) {}
  };

  // Virtual pay
  const payWithCreditCard = async (params: {
    paymentType: string;
    cardTransactionReff: string;
    status: string;
    amtTendered: number | string;
    cardHolderName: string;
    cardNumber: string;
    expiry: string;
    cvv: string;
    paymentTerminal?: string;
    surchargeFee?: number;
  }) => {
    try {
      const data = {
        docket: docketId.value,
        paymentType: params.paymentType,
        amount: Number(checkoutStore.payingNow).toFixed(2),
        cardTransactionReff: params.cardTransactionReff,
        status: params.status,
        amtTendered: params?.amtTendered,
        cardName: params?.cardHolderName,
        cardNumber: params?.cardNumber,
        cardExpiry: params?.expiry,
        cardCvc: params.cvv,
        createdBy: userId.value,
        paymentTerminal: params?.paymentTerminal,
        surchargeAmount: params?.surchargeFee,
      };
      const record = await pb.collection("docketPayments").create(data);
      if (record) {
        docketPaymentId.value = record.id;
      }
    } catch (e) {}
  };

  // pay with gift card or coupons
  const applyValueToken = async (params: {
    status?: string;
    paymentType: "Coupon" | "Gift Card" | "Event Ticket";
    amount: number;
    identifier: string;
    qty: number;
    toPay?: number;
    notes?: string;
    type?: string;
    bookingId?: string;
    docketId?: string;
    totalPaid?: number;
  }) => {
    try {
      // Set docket ID if provided
      if (params?.docketId) docketId.value = params.docketId;

      // Helper to get specific fields for each payment type
      const getPaymentSpecificFields = (params: any) => {
        switch (params.paymentType) {
          case "Coupon":
            return { coupon: params.identifier, couponQty: params.qty };
          case "Gift Card":
            return { giftCard: params.identifier };
          case "Event Ticket":
            return { eventTicket: params.identifier };
          default:
            return {};
        }
      };

      // Prepare data for payment record creation
      const data = {
        docket: docketId.value,
        paymentType: params.paymentType,
        amount: Number(checkoutStore.payingNow).toFixed(2),
        status: params.status,
        createdBy: userId.value,
        notes: params.notes,
        ...getPaymentSpecificFields(params),
      };

      // Create payment record and update balances
      const record = await pb.collection("docketPayments").create(data);
      docketPaymentId.value = record.id;

      // Calculate remaining balance and status
      const balanceRemaining =
        (params.toPay || checkoutStore.balanceToPay) - checkoutStore.payingNow;
      const status = balanceRemaining <= 0 ? "Paid" : "Partial";
      const due =
        balanceRemaining <= 0 ? 0 : Number(balanceRemaining).toFixed(2);

      await pb.collection("dockets").update(docketId.value, {
        status,
        due,
        updatedBy: userId.value,
      });

      // Check approval and booking due updates
      if (params.status === "Approved") await paymentApproved(docketId.value);

      if (params?.type === "Booking") {
        await pb.collection("bookings").update(params.bookingId, {
          due,
          paid: (params.totalPaid || 0) + params.amount,
        });
      }

      // TODO: REMOVE CODE AFTER SERVER UPDATED WITH REAL VALUE OF COUPONS/GIFT
      // await updateTokenBalance(params);

      return record;
    } catch (e) {
      console.error(e);
    }
  };

  // TODO: REMOVE CODE AFTER SERVER UPDATED WITH REAL VALUE OF COUPONS/GIFT
  // Consolidated balance/quantity update for token
  // const updateTokenBalance = async (params: any) => {
  //   // Helper to map paymentType to collection name
  //   const getCollectionName = (paymentType: string) => {
  //     return {
  //       Coupon: "coupons",
  //       "Gift Card": "giftCards",
  //       "Event Ticket": "eventTickets",
  //     }[paymentType];
  //   };

  //   const collectionName: string = getCollectionName(params.paymentType) || "";
  //   const response = await pb
  //     .collection(collectionName)
  //     .getOne(params.identifier);

  //   // Update remaining balance or quantity
  //   if (params.paymentType === "Coupon") {
  //     response.qtyRemaining -= params.qty;
  //   } else {
  //     const balanceField =
  //       params.paymentType === "Gift Card"
  //         ? "balanceRemaining"
  //         : "barRemaining";
  //     response[balanceField] = (
  //       Number(response[balanceField]) - params.amount
  //     ).toFixed(2);
  //   }

  //   await pb.collection(collectionName).update(params.identifier, response);
  // };

  // Update requiresEmail in docketPayments
  const emailReceipt = async () => {
    if (docketId.value) {
      const data = {
        docketID: docketId.value,
        email: email.value,
        createdBy: userId.value,
      };

      const response = await pb.collection("docketReceipts").create(data);

      if (response) {
        toast.success("Email Receipt Sent", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  // Update requiresPrinting in docketPayments
  const printReceipt = async () => {
    if (docketId.value) {
      const data = {
        docketID: docketId.value,
        email: email.value,
        requiresPrinting: true,
        createdBy: userId.value,
      };

      const response = await pb.collection("docketReceipts").create(data);

      if (response) {
        toast.success("Receipt Printed", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  // get the list of docketLine for docket
  const getDocketLinesItems = async (dId = null) => {
    try {
      // if not available take from param
      const docket_Id = route?.query?.did || dId;
      const response = await pb.collection("docketLines").getFullList({
        filter: `docket = '${docket_Id}'`,
        expand: "product",
        sort: "sortOrder",
      });

      if (response) {
        // save a print to store
        originalDocketItems.value = response;
        // available items update to store
        let availableItems = cloneDeep(response);
        availableItems.forEach(
          (el: {
            isAvailable: number;
            qty: any;
            processing: any;
            processed: any;
            isPaymentCompleted: boolean;
          }) => {
            if (el.qty === el.processed) {
              el.isPaymentCompleted = true;
            } else {
              // el.isAvailable = el.qty - el.processed;
              el.isAvailable =
                el.qty === el.processed
                  ? el.qty - el.processed
                  : el.qty - el.processing;
              el.isPaymentCompleted = false;
            }
          }
        );

        docketLinesItems.value = availableItems.filter(
          (el: { qty: any; processing: any; isPaymentCompleted: any }) =>
            el.isPaymentCompleted ||
            (el.qty !== el.processing && !el.isPaymentCompleted)
        );

        // processing list items update to store
        let processingListItems: RecordModel[] = [];
        cloneDeep(response).forEach((el: any) => {
          if (
            el.processing &&
            el.qty > el.processed &&
            el.processing > el.processed
          ) {
            // let inProcessing = el.qty - el.processed;
            let inProcessing = el.processing - el.processed;
            el.inProcessing = inProcessing;
            processingListItems.push(el);
          }
        });
        processingItems.value = cloneDeep(processingListItems);
      }
    } catch (e) {}
  };

  const getDocketLinesItemsList = async () => {
    const response = await pb.collection("docketLines").getFullList({
      filter: `docket = '${docketId.value}'`,
      expand: "product",
    });
    // reduce quantity and price as processed and processing key
    // available items update to store
    let availableItems = cloneDeep(response);
    availableItems.forEach(
      (el: {
        isAvailable: number;
        qty: any;
        processing: any;
        processed: any;
        isPaymentCompleted: boolean;
      }) => {
        if (el.qty === el.processed) {
          el.isPaymentCompleted = true;
        } else {
          el.isAvailable = el.qty - el.processed;
          el.isPaymentCompleted = false;
        }
      }
    );

    checkoutStore.docketLinesItems = availableItems.filter(
      (el: { qty: any; processing: any; isPaymentCompleted: any }) =>
        el.isPaymentCompleted ||
        (el.qty !== el.processing && !el.isPaymentCompleted)
    );
  };

  // Update docketLine item status to processed when payment done from items tab
  const updateItemStatus = async (status: string) => {
    // not required which from other tab payment
    if (checkoutStore.activePaymentTab !== 2) {
      return;
    }

    let records = [];
    //when process one or more item from item tab
    let findItemsInProcessing = checkoutStore.processingItems;

    if (findItemsInProcessing.length) {
      records = findItemsInProcessing;
    } else {
      let filter = `docket="${docketId.value}"`;

      records = await pb.collection("docketLines").getFullList({
        filter: filter,
      });
    }

    records.forEach(async (item) => {
      // update processed key as number of quantity of item as its processed payment
      if (status === "Processing") {
        item.processing = item.inProcessing + item.processed;
        item.updatedBy = userId.value;
      }

      await pb.collection("docketLines").update(item.id, item);
    });
  };

  // it will be use to update docket line status when payment canceled or declined
  const updateDocketLineStatusAvailable = async () => {
    await changeItemsStatus();
    setTimeout(async () => {
      await getDocketLinesItems();
      checkoutStore.processingItems = [];
    }, 1000);
  };

  // changes status for the items
  const changeItemsStatus = async () => {
    checkoutStore.processingItems.forEach(async (item) => {
      const record = await pb
        .collection("docketLines")
        .getFirstListItem(`id="${item?.id}" && product="${item?.product}"`);

      record.status = "";
      record.updatedBy = userId.value;
      await pb.collection("docketLines").update(record.id, record);
    });
  };

  // change item status to processed
  const updateStatusToProcessed = async () => {
    // not required which from other tab payment
    if (checkoutStore.activePaymentTab !== 2) {
      return;
    }
    let records = [];
    //when process one or more item from item tab
    let findItemsInProcessing = checkoutStore.processingItems;

    if (findItemsInProcessing.length) {
      records = findItemsInProcessing;
    } else {
      let filter = `docket="${docketId.value}"`;
      records = await pb.collection("docketLines").getFullList({
        filter: filter,
      });
    }
    records.forEach(async (item) => {
      // update processed key as number of quantity of item as its processed payment
      // item.processing = item.inProcessing + item.processed;
      item.processed = item.processing
        ? item.processing
        : item.inProcessing + item.processed;
      item.updatedBy = userId.value;
      await pb.collection("docketLines").update(item.id, item);
    });
    setTimeout(async () => {
      await getDocketLinesItems();
    }, 1000);
  };

  // get one docket
  const getOneDocket = async () => {
    const docket = await pb.collection("dockets").getOne(docketId.value, {
      expand: "discountCode",
    });

    // add discount code and discount code data into discount store
    discountCode.value = docket?.discountCode || null;
    discountCodeData.value = docket?.expand?.discountCode || {};

    return docket;
  };

  const refreshListItems = () => {
    checkoutStore.docketLinesItems = checkoutStore.docketLinesItems.filter(
      (el: any) => {
        if (el.hasOwnProperty("isAvailable") && el.isAvailable === 0) {
          return false;
        } else {
          return true;
        }
      }
    );
    checkoutStore.processingItems = checkoutStore.processingItems.filter(
      (el: any) => {
        if (el.hasOwnProperty("inProcessing") && el.inProcessing === 0) {
          return false;
        } else {
          return true;
        }
      }
    );
  };

  const handleSwapProductServices = (
    id: any,
    containerType: string,
    selectedItem: SelectedItem | undefined,
    checkoutStore: CheckoutStore | undefined
  ): void => {
    // closure function for manage Flag to show virtual quantity
    const manageFlag = (
      items: any[],
      id: any,
      key: string,
      operation: string | undefined
    ) => {
      items.map((el) => {
        if (el.id === id) {
          if (operation === "INC") {
            el[key] += 1;
          }
          if (operation === "DEC") {
            el[key] -= 1;
          }
        }
      });
    };

    // type: processing
    if (containerType === "Processing") {
      if (
        checkoutStore?.processingItems?.some((el) => el.id === selectedItem?.id)
      ) {
        // update flag in processing list
        manageFlag(checkoutStore.processingItems, id, "inProcessing", "INC");
      } else {
        if (selectedItem) {
          selectedItem.inProcessing = 1;
          checkoutStore?.processingItems.push(selectedItem);
        }
      }
      checkoutStore?.docketLinesItems.map((el) => {
        if (el.id === selectedItem?.id) {
          if (el.isAvailable) {
            el.isAvailable -= 1;
          } else {
            el.isAvailable = el.qty - 1;
          }
        }
      });
    } else {
      // type: available
      if (
        checkoutStore?.docketLinesItems?.some(
          (el) => el.id === selectedItem?.id
        )
      ) {
        // update flag to available list
        manageFlag(checkoutStore.docketLinesItems, id, "isAvailable", "INC");
      } else {
        if (selectedItem) {
          selectedItem.inProcessing = 0;
          selectedItem["isAvailable"] = 1;
          checkoutStore?.docketLinesItems.push(selectedItem);
        }
      }
      // update flag in processing list
      checkoutStore?.processingItems.map((el) => {
        if (el.id === selectedItem.id) {
          if (el.inProcessing) {
            el.inProcessing -= 1;
          } else {
            el.inProcessing = el.qty - 1;
          }
        }
      });
    }
    // remove not existing list
    refreshListItems();
  };

  // docket get payment
  const getDocketPayment = async () => {
    try {
      const docketPayments = await pb
        .collection("docketPayments")
        .getList(1, 50, {
          filter: `docket = '${docketId.value}' && status = 'Approved'`,
        });

      // Use reduce to calculate the total payment amount
      const paymentDone = docketPayments?.items?.reduce(
        (total, payment) => total + payment.amount,
        0
      );

      return paymentDone;
    } catch (e) {
      return 0; // Return 0 in case of error
    }
  };

  const updateDocketDiscountField = async (
    docketId: string,
    field: string,
    discount: number
  ) => {
    const docket = await pb.collection("dockets").update(docketId, {
      [field]: discount,
      discountSources: [...discountSources.value, "POS"],
      updatedBy: userId.value,
    });

    // Update local docket discount data
    docketDiscountData.value = {
      bookingDiscount: docket.discountBookings,
      productDiscount: docket.discountProducts,
      eventDiscount: docket.discountEvents,
    };
  };

  const updateDocketDiscount = async (
    type: keyof typeof DISCOUNT_FIELDS,
    docketId: string,
    discount: number
  ) => {
    try {
      const discountField = DISCOUNT_FIELDS[type];

      await updateDocketDiscountField(docketId, discountField, discount);
    } catch (error) {
      console.error("Error updating docket discount:", error);
    }
  };

  const discountPercentage = (item: RecordModel) => {
    const product = productsMap.value[item.product];
    const { maxDiscount = 0, memberBookingDiscountApplies } = product || {};

    // Return 0 if discount data is unavailable
    if (isEmpty(docketDiscountData?.value)) {
      return 0;
    }

    // Determine discount type and applicable discount
    const discountType = memberBookingDiscountApplies
      ? "bookingDiscount"
      : "productDiscount";
    const applicableDiscount = docketDiscountData.value[discountType] || 0;

    // Handle discounts for specific transaction types
    if (!product) {
      const { transactionType } = item || {};

      const transactionDiscounts: Record<string, string> = {
        "Booking Payment": "bookingDiscount",
        Event: "eventDiscount",
      } as any;

      const discountKey = transactionDiscounts[transactionType] as any;
      if (discountKey) {
        return Math.min(docketDiscountData.value[discountKey] || 0, 100);
      }
    }

    // Apply the smaller of the applicable discount and the max discount
    return Math.min(applicableDiscount, maxDiscount);
  };

  // Initialization logic inside the useDocket itself
  const init = async () => {
    if (!isInitialized.value) {
      isInitialized.value = true; // Ensure it's initialized only once
      if (route?.query?.did) {
        docketId.value = route?.query?.did;
      }
      if (localStorage.getItem("email"))
        email.value = localStorage.getItem("email");
    }
  };

  watch(
    route,
    (newValue, oldValue) => {
      if (route?.query?.did) {
        docketId.value = route?.query?.did;
      }
    },
    { immediate: true }
  );

  // Automatically initialize when the store is accessed or used
  watchEffect(async () => {
    if (!isInitialized.value) {
      await init();
    }
  });

  return {
    docketId,
    docketPaymentId,
    email,
    PayWithTerminal,
    PayWithCash,
    printReceipt,
    emailReceipt,
    getDocketLinesItems,
    updateItemStatus,
    updateDocketLineStatusAvailable,
    getOneDocket,
    updateStatusToProcessed,
    updateProcessedStatus,
    handleSwapProductServices,
    getDocketPayment,
    updateDocketDiscount,
    discountPercentage,
    payWithInvoice,
    payWithManualTerm,
    payWithCreditCard,
    applyValueToken,
    PayWithUserAccount,
  };
};
