import { formatPrice } from "@/utils/Formatting";
import { isEmpty, isEmptyArray, isNullOrUndefined } from "./index";

// 👉 Required Validator
export const requiredValidator = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false)
    return "This field is required";

  return !!String(value).trim().length || "This field is required";
};

export const requiredValue = (value: unknown) => {
  if (isNullOrUndefined(value) || isEmptyArray(value))
    return "This field is required";

  return !!String(value).trim().length || "This field is required";
};

// Number Validator
export const numberValidator = (value: unknown) => {
  return !isNaN(parseFloat(value as string)) && isFinite(value as number)
    ? true
    : "Must be a number.";
};

// 👉 Email Validator
export const emailValidator = (value: unknown) => {
  if (isEmpty(value)) return true;

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (Array.isArray(value))
    return (
      value.every((val) => re.test(String(val))) ||
      "The Email field must be a valid email"
    );

  return re.test(String(value)) || "The Email field must be a valid email";
};

// 👉 Password Validator
export const passwordValidator = (password: string) => {
  const regExp = /.{8,}/;

  const validPassword = regExp.test(password);

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || "Password must contain min 8 chars"
  );
};

// 👉 Confirm Password Validator
export const confirmedValidator = (value: string, target: string) =>
  value === target || "Passwords do not match";

// 👉 Between Validator
export const betweenValidator = (value: unknown, min: number, max: number) => {
  const valueAsNumber = Number(value);

  return (
    (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) ||
    `Enter number between ${min} and ${max}`
  );
};

// 👉 Integer Validator
export const integerValidator = (value: unknown) => {
  if (isEmpty(value)) return true;

  if (Array.isArray(value))
    return (
      value.every((val) => /^-?[0-9]+$/.test(String(val))) ||
      "This field must be an integer"
    );

  return /^-?[0-9]+$/.test(String(value)) || "This field must be an integer";
};

// 👉 Regex Validator
export const regexValidator = (
  value: unknown,
  regex: RegExp | string
): string | boolean => {
  if (isEmpty(value)) return true;

  let regeX = regex;
  if (typeof regeX === "string") regeX = new RegExp(regeX);

  if (Array.isArray(value))
    return value.every((val) => regexValidator(val, regeX));

  return regeX.test(String(value)) || "The Regex field format is invalid";
};

export const customRegexValidator = (
  value: unknown,
  regex: RegExp | string,
  errorMessage: string
): string | boolean => {
  if (isEmpty(value)) return true;

  let regeX: RegExp;

  if (typeof regex === "string") {
    // Add the 's' flag to ensure dot matches newline characters
    regeX = new RegExp(regex, "s");
  } else {
    regeX = regex;
  }

  if (Array.isArray(value)) {
    return value.every((val) => regexValidator(val, regeX));
  }

  return regeX.test(String(value)) || errorMessage;
};

// 👉 Alpha Validator
export const alphaValidator = (value: unknown) => {
  if (isEmpty(value)) return true;

  return (
    /^[A-Z]*$/i.test(String(value)) ||
    "The Alpha field may only contain alphabetic characters"
  );
};

// 👉 URL Validator
export const urlValidator = (value: unknown) => {
  if (isEmpty(value)) return true;

  const re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  return re.test(String(value)) || "URL is invalid";
};

// 👉 Length Validator
export const lengthValidator = (value: unknown, length: number) => {
  if (isEmpty(value)) return true;

  return (
    String(value).length >= length ||
    `The Min Character field must be at least ${length} characters`
  );
};

// 👉 Alpha-dash Validator
export const alphaDashValidator = (value: unknown) => {
  if (isEmpty(value)) return true;

  const valueAsString = String(value);

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || "All Character are not valid";
};

// 👉 Allow two decimal point
export const decimalRule = (value: unknown) => {
  if (isEmpty(value)) return true;

  const decimalPattern = /^\d*(\.\d{0,2})?$/;
  // Check if value is a valid number with up to two decimal places
  return (
    decimalPattern.test(String(value)) ||
    "This field must be a number and only two decimal places"
  );
};

// Not allowed less amount to pay
export const amountRule = (value: any, max: number, field = "amount") => {
  const amount = parseFloat(value);
  if (isNaN(amount) || amount < 0 || (max !== undefined && amount < max)) {
    return `The ${field} must not be less than ${formatPrice(max)}.`;
  }
};

// Allow partial amount to pay
export const partialAmountRule = (value: any, max: number, field: any) => {
  const amount = parseFloat(value);
  if (isNaN(amount) || amount < 0 || (max !== undefined && amount > max)) {
    return `${field} must be between ${formatPrice(0)} and ${formatPrice(
      max
    )}.`;
  }
};

// Allow discount between 0 and 50
export const discountRule = (value: any, field: any) => {
  const amount = parseFloat(value);
  if (isNaN(amount) || amount < 0 || amount > 50) {
    return "The discount must be between 0 and 50.";
  }
};

export const splitRule = (min: number, max: number) => {
  return (value: number) =>
    (value >= min && value <= max) ||
    `The split must be between ${min} and ${max}.`;
};

// min validator
export const minValidator = (value: any, min: number) => {
  /**
   * @param value: value of the field
   * @param min: min length required
   * @param max: max length required
   * @param field: field name
   */
  if (isEmpty(value)) return true;

  if (value < min) {
    return `The amount must be at least ${min}`;
  } else {
    return true;
  }
};

// minMax validator
export const minMaxValidator = (value: any, min: number, max: number) => {
  /**
   * @param value: value of the field
   * @param min: min length required
   * @param max: max length required
   * @param field: field name
   */
  if (isEmpty(value)) return true;

  if (value < min) {
    return `The amount must be at least ${min}`;
  } else if (value > max) {
    return `The amount must be less than or equal to ${max}`;
  } else {
    return true;
  }
};

// credit card Expiry date validator
export const validateExpiryDate = (value: string) => {
  if (!value) return "Expiry date is required";

  // Regular expression to check if the format is MM/YY
  const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
  if (!regex.test(value)) return "Invalid expiry date format";

  return true;
};

// validate credit card cvv number
export const validateCVVNumber = (value: number | string) => {
  // Convert value to string to handle numeric input properly
  const stringValue = value.toString();

  // Regular expression to check if the CVV is either 3 or 4 digits long
  const regex = /^\d{3,4}$/;

  if (!regex.test(stringValue)) return "Invalid CVV number format";

  return true;
};

export const birthDateValidator = (value: string) => {
  // Regular expression to check if the date format is YYYY-MM-DD
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  if (!regex.test(value)) return "Invalid birth date format";

  // Check if the date is a valid calendar date
  const [year, month, day] = value.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  // Ensure the constructed date matches the input (accounts for invalid dates like 2024-02-30)
  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day
  ) {
    return "Invalid birth date";
  }

  // Ensure the birth date is not in the future
  const today = new Date();
  if (date > today) return "Birth date cannot be in the future";

  return true;
};

export const zeroValidator = (value: number) => {
  if (isEmpty(value)) return true;
  if (value <= 0) {
    return `Please enter a valid number`;
  }
};

export const shortCodeValidator = (value: unknown, length: number) => {
  if (isEmpty(value)) return true;

  return (
    String(value).length >= length ||
    `The Min Character field must be at least ${length} characters`
  );
};
