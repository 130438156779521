import { moment } from "@/utils/useTimeZone";

export const formatPrice = (price: number | undefined) => {
  return `$${Number(price).toFixed(2)}`;
};

export const textTruncate = (input: string) =>
  input.length > 25 ? `${input.substring(0, 25)}...` : input;

export const GSTFromTotal = (total: number, GSTRate: number) => {
  return Number(Number(total - total * (100 / (100 + GSTRate))).toFixed(2));
};

export const manageNouns = (count: number, value: string) => {
  if (count == 1) {
    return `${count} ${value.slice(0, -1)}`;
  }
  return `${count} ${value}`;
};

export const formatTime = (
  time: string,
  toFormat: "12Hour" | "24Hour"
): string | { hour: number; minute: number } => {
  if (toFormat === "12Hour") {
    // Convert 24-hour to 12-hour format
    const [hour, minute] = time.split(":").map(Number);

    const period = hour >= 12 ? "PM" : "AM";
    const convertedHour = hour % 12 || 12; // Convert 0 or 12-hour values to 12

    return `${String(convertedHour).padStart(2, "0")}:${String(minute).padStart(
      2,
      "0"
    )} ${period}`;
  } else if (toFormat === "24Hour") {
    // Convert 12-hour to 24-hour format
    const [timePart, period] = time.split(" ");
    const [hour, minute] = timePart.split(":").map(Number);

    let convertedHour = hour;
    if (period.toUpperCase() === "PM" && hour !== 12) {
      convertedHour += 12;
    } else if (period.toUpperCase() === "AM" && hour === 12) {
      convertedHour = 0;
    }

    return {
      hour: convertedHour,
      minute,
    };
  } else {
    throw new Error("Invalid format specified. Use '12-hour' or '24-hour'.");
  }
};

// Method to get the activity name and color by its id
export const resolveActivityIdsToNamesAndColors = (activies: any) => {
  return activies.map((activity: any) => {
    return {
      name: activity?.name || "NA",
      color: activity?.color || "primary",
    };
  });
};

export const getBookingStatus = (actualStartTime: any, actualDuration: any) => {
  if (!actualStartTime) {
    return "futureBooking";
  }

  const startTime = moment(actualStartTime);
  const endTime = startTime.clone().add(actualDuration, "minutes");

  const now = moment();

  if (now.isBetween(startTime, endTime)) {
    return "inprogress";
  }

  if (now.isAfter(endTime)) {
    return "completed";
  }

  return "futureBooking";
};

export const Round2String = (price: any, numberOfDecimals: number) => {
  return Round2Num(Number(price), numberOfDecimals).toFixed(numberOfDecimals);
};
export const Round2Num = (price: number, numberOfDecimals: number) => {
  const Result1 =
    Math.round(price * Math.pow(10, numberOfDecimals)) /
    Math.pow(10, numberOfDecimals);
  return Result1;
};

export const padGiftCards = (str: string) => {
  str = String(str);
  return str.padStart(15, '0');
}