<script setup lang="ts">
import { requiredValidator } from "@/@core/utils/validators";
import type { Activity } from "@/components/types";

// interface
interface Props {
  isOpen: boolean;
  activity?: Activity;
  isRecurring?: boolean;
  type?: string;
  isAdmin?: boolean;
}

// props
const props = defineProps<Props>();

// Emit
const emit = defineEmits<{
  (e: "closeDialog", val: boolean): void;
  (e: "cancelBooking", val: boolean): void;
}>();

// data
const cancelAgreement = ref<boolean>(false);
const refVForm = ref<any>("");

// computed
const formattedCancelAgreement = computed(() => {
  return props?.activity?.cancelAgreement?.replace(
    /\[(.*?)\]\((.*?)\)/g,
    '<a href="$2" target="_blank">$1</a>'
  );
});

// Method
const validateCancel = async (all: boolean = false) => {
  const { valid } = await refVForm.value.validate();
  if (valid) {
    emit("cancelBooking", all);
  }
};

watch(
  () => [props?.isOpen],
  ([newValue]) => {
    if (newValue && props.isAdmin) {
      cancelAgreement.value = true;
    }
  },
  { immediate: true }
);

// watch
watchEffect(() => {
  if (!props?.isOpen) {
    cancelAgreement.value = false;
  }
});
</script>

<template>
  <VDialog :model-value="isOpen" persistent no-click-animation max-width="490">
    <VCard>
      <div class="d-flex align-center justify-space-between bg-error">
        <!-- 👉 Header -->
        <VCardTitle>Remove booking</VCardTitle>
        <VBtn
          icon="mdi-close"
          size="large"
          variant="text"
          class="rounded-0"
          @click="emit('closeDialog', false)"
        />
      </div>
      <VCardText>
        <div class="mb-5">
          Are you sure you want to remove this
          {{
            type === "Event" ? "Event ?" : `${props?.activity?.name} booking ?`
          }}
        </div>
        <VForm ref="refVForm">
          <VCheckbox
            v-if="type !== 'Event'"
            v-model="cancelAgreement"
            :rules="[requiredValidator]"
          >
            <template #label>
              <div v-html="formattedCancelAgreement" />
            </template>
          </VCheckbox>
        </VForm>
      </VCardText>
      <VCardActions>
        <v-spacer></v-spacer>
        <VBtn
          color="grey"
          variant="outlined"
          @click="emit('closeDialog', false)"
        >
          No - Keep
        </VBtn>

        <!-- Conditionally show options if it's a recurring booking -->
        <template v-if="props?.isRecurring">
          <VBtn
            color="error"
            variant="flat"
            text
            @click="validateCancel(false)"
          >
            Yes - Remove this one
          </VBtn>
          <VBtn color="error" variant="flat" text @click="validateCancel(true)">
            Yes - Remove all
          </VBtn>
        </template>

        <!-- Show only single delete option for non-recurring bookings -->
        <template v-else>
          <VBtn color="error" variant="flat" text @click="validateCancel">
            Yes - Remove
          </VBtn>
        </template>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
