import { pb } from "@/utils/PocketBaseAdapter";
import { useConvertToCSV } from "@/utils/useConvertToCSV";
import moment from "moment";
import { useDiscountCode } from "./useDiscountCode";

export const useEventStore = defineStore("events", () => {
  // store
  const discount = useDiscountCode();

  const { discountCode } = storeToRefs(discount);

  // Data
  const addEditEventDialog = ref<boolean>(false);
  const eventData = ref<any>({});
  const eventList = ref<any[]>();
  const totalEvents = ref<number>(0);
  const totalTickets = ref<number>(0);
  const eventBookingsId = ref<string[]>([]);
  const eventId = ref<string>("");
  const recurringEventId = ref<string>("");
  const recurringDetails = ref<any>({});
  const isExportLoading = ref<boolean>(false);
  const selectedEvent = ref<any>({});
  const isConfirmEditEventDialog = ref<boolean>(false);
  const isEventPending = ref<boolean>(false);
  const confirmEditDialog = ref<any>({
    type: "", // Booking/Event
    title: "Edit Booking",
    alertMessage: "Please confirm your action.",
  });
  const eventTicketsList = ref<any[]>([]);
  const eventProducts = ref<any>([]);
  const soldEvents = ref<any[]>([]);

  // Computed
  const findSoldTicket = computed(() => (product: string) => {
    const productData = soldEvents?.value?.filter(
      (p: any) => p.productRecord === product
    );

    return productData?.length;
  });

  // Methods
  const getEvents = async (params: any, areaIds: string[]) => {
    try {
      const { search: searchText, sortBy, currentPage, perPage } = params;

      // Construct filter conditions
      const filters = [searchText ? `name ~ "${searchText}"` : null]
        // areaIds.length
        //   ? `(${areaIds.map((id) => `bookings.area = "${id}"`).join(" || ")})`
        //   : null,
        ?.filter(Boolean)
        ?.join(" && ");

      // Construct sort parameter
      let sortParam = "";
      if (sortBy && sortBy.length) {
        sortParam =
          sortBy[0].order === "desc" ? `-${sortBy[0].key}` : sortBy[0].key;
      }

      // Fetch events from PocketBase with sorting and filtering
      const events = await pb
        .collection("events")
        .getList(currentPage, perPage, {
          filter: filters,
          sort: sortParam, // Add the sort parameter to the request
          expand: "bookings.owner,bookings.docketLine,bookings.area", // Ensure to expand all necessary fields
          requestKey: null,
        });

      if (events?.items) {
        eventList.value = events.items;
        totalEvents.value = events.totalItems;
      }

      const eventProductList = await pb.collection("products").getFullList({
        filter: events.items
          ?.map((event: any) => `event ~ '${event?.id}'`)
          .join(" || "),
        fields: "id,event,eventTicketLimit,",
      });

      eventProducts.value = eventProductList;
    } catch (error) {
      console.error("Error fetching events: ", error);
    }
  };

  const mapActivitiesToAreas = (bookingsList: any[]) => {
    return bookingsList.reduce((acc, { activity, area }) => {
      // Initialize the activity array if it doesn't exist, then push the area
      acc[activity] = acc[activity] || [];
      acc[activity].push(area);
      return acc;
    }, {} as Record<string, string[]>);
  };

  const handleEventEdit = (event: any) => {
    const firstBooking = event?.expand?.bookings?.[0];
    if (!firstBooking) return; // Exit if no bookings

    let totalDue = 0;
    let totalPaid = 0;

    const { startTime, duration, owner, location, expand } = firstBooking;
    const expandDocketLine = expand?.docketLine?.expand;

    if (
      expandDocketLine &&
      expandDocketLine?.docket &&
      expandDocketLine?.docket?.discountCode
    ) {
      discountCode.value = expandDocketLine?.docket?.discountCode;
    }

    // Calculate total due & totalPaid for all bookings
    if (event?.expand?.bookings?.length) {
      for (const booking of event.expand.bookings) {
        totalDue += booking.due || 0;
        totalPaid += booking.paid || 0;
      }
    }

    // Extract docketLine from first booking's expand property
    const { docketLine } = expand || {};

    // Construct event object
    eventData.value = {
      id: event?.id,
      name: event?.name,
      description: event?.description,
      startDate: startTime,
      duration,
      activity: mapActivitiesToAreas(event?.expand?.bookings),
      owner,
      location,
      due: totalDue,
      docket: docketLine?.docket,
      recurringConfig: event?.recurring || {},
      ticketConfig: {
        trackTickets: event?.trackTickets,
      },
      paid: totalPaid || 0,
      discount: firstBooking?.discount || 0,
    };
    // open the dialog
    addEditEventDialog.value = true;
  };

  const handleExportEvents = async (params: any, areaIds: string[]) => {
    try {
      isExportLoading.value = true;

      const { search: searchText, sortBy } = params;
      // Construct filter conditions
      const filters = [searchText ? `name ~ "${searchText}"` : null]
        // areaIds.length
        //   ? `(${areaIds.map((id) => `bookings.area = "${id}"`).join(" || ")})`
        //   : null,
        ?.filter(Boolean)
        ?.join(" && ");

      // Construct sort parameter
      let sortParam = "";
      if (sortBy && sortBy.length) {
        sortParam =
          sortBy[0].order === "desc" ? `-${sortBy[0].key}` : sortBy[0].key;
      }

      const eventsList = await pb.collection("events").getFullList({
        filter: filters,
        sort: sortParam,
        expand: "bookings.owner,bookings.docketLine,bookings.area",
      });

      const mappedEvents = eventsList.map((event) => {
        const bookings = event?.expand?.bookings || [];
        const owner = bookings[0]?.expand?.owner || {};
        const areaNumbers =
          bookings
            .map((booking: any) => booking.expand.area?.areaNumber)
            .join(",") || "";
        const totalDue = bookings.reduce(
          (sum: any, { due = 0 }) => sum + due,
          0
        );
        const totalValue = bookings.reduce(
          (sum: any, { value = 0 }) => sum + value,
          0
        );
        const { duration = 0, startTime = "" } = bookings[0] || {};

        return {
          eventName: event.name || "",
          eventAreas: areaNumbers,
          eventDescription: event.description || "",
          owner: `${owner.firstName || ""} ${owner.lastName || ""}`.trim(),
          duration,
          startTime: moment(startTime).format("DD-MM h:mm a"),
          total: totalValue,
          due: totalDue,
        };
      });

      const headers = [
        { title: "Name", key: "eventName" },
        { title: "Bays", key: "eventAreas" },
        { title: "Description", key: "eventDescription" },
        { title: "Owner", key: "owner" },
        { title: "Duration", key: "duration" },
        { title: "StartTime", key: "startTime" },
        { title: "Total", key: "total" },
        { title: "Due", key: "due" },
        { title: "Action", key: "action" },
      ];

      useConvertToCSV(mappedEvents, headers, "events");

      isExportLoading.value = false;
    } catch (e) {
      console.error(e);
      isExportLoading.value = false;
    }
  };

  const handleExportEventTickets = async (params: any) => {
    try {
      isExportLoading.value = true;

      const { search: searchText, sortBy } = params;
      // Construct filter conditions
      const filters = [searchText ? `name ~ "${searchText}"` : null]
        ?.filter(Boolean)
        ?.join(" && ");

      // Construct sort parameter
      let sortParam = "";
      if (sortBy && sortBy.length) {
        sortParam =
          sortBy[0].order === "desc" ? `-${sortBy[0].key}` : sortBy[0].key;
      }

      const ticketList = await pb.collection("eventTickets").getFullList({
        filter: filters,
        sort: sortParam,
        expand: "event,productRecord,user",
      });

      const mappedTickets = ticketList?.map((ticket) => {
        return {
          name: ticket.name || "",
          event: ticket?.expand?.event?.name || "",
          user:
            ticket?.expand?.user?.firstName +
            " " +
            ticket?.expand?.user?.lastName,
          product: ticket?.expand?.user?.product?.name || "",
          used: ticket?.used,
          paid: ticket?.paid,
        };
      });

      const headers = [
        { title: "Name", key: "name" },
        { title: "Event", key: "event" },
        { title: "User", key: "user" },
        { title: "Products", key: "products" },
        { title: "Used", key: "used" },
        { title: "Paid", key: "paid" },
        { title: "Total", key: "total" },
        { title: "Action", key: "actions" },
      ];

      useConvertToCSV(mappedTickets, headers, "eventTickets");

      isExportLoading.value = false;
    } catch (e) {
      console.error(e);
      isExportLoading.value = false;
    }
  };

  const checkEventType = async (params: any) => {
    try {
      selectedEvent.value = params;
      const recurringEvents = await pb
        .collection("recurringEvents")
        .getList(1, 50, {
          filter: `events ~ '${params.id}'`,
        });
      if (recurringEvents && recurringEvents?.items?.length) {
        // show edit all or single dialog
        const { id, interval, endDate, events, days, frequency } =
          recurringEvents?.items[0];

        selectedEvent.value = {
          ...selectedEvent.value,
          recurring: {
            interval,
            endDate,
            days,
            frequency,
          },
        };

        recurringDetails.value = {
          isEditAll: false,
          recurringId: id,
          events,
        };

        isConfirmEditEventDialog.value = true;
      } else {
        handleEventEdit(params);
      }
    } catch (e) {}
  };

  const fetchTickets = async (params: any = null) => {
    try {
      // Build the filter string
      const filter = [
        params?.search ? `name ~ "${params.search}"` : null,
        params?.event ? `event = "${params.event}"` : null, // Add event filter
      ]
        .filter(Boolean) // Remove null values
        .join(" && "); // Join with AND condition

      // Sort parameter logic remains the same
      const sortParam = params?.sortBy?.length
        ? params.sortBy[0].order === "desc"
          ? `-${params.sortBy[0].key}`
          : params.sortBy[0].key
        : "";

      // Define options for the request
      const options = {
        expand: "event,productRecord,user",
        filter: filter || "", // Use the filter if it's not empty
        sort: sortParam || "-created",
      };

      // Fetch the ticket list
      const resultList = await pb
        .collection("eventTickets")
        .getList(params?.currentPage, params?.perPage, options);

      // Set the result to the reactive variables
      if (resultList) {
        eventTicketsList.value = resultList?.items;
        totalTickets.value = resultList.totalItems;
      }
    } catch (e) {
      console.error("Error fetching tickets:", e); // Log error for debugging
    }
  };

  /**
   * Fetches the total number of sold events for a given event ID.
   *
   * @param {string} eventId - The ID of the event for which to fetch the total sold events.
   * @returns {Promise<number>} - A promise that resolves to the total number of sold events for the given event ID.
   * @throws Will throw an error if the request fails or if the response does not contain the expected data.
   */
  const fetchTotalSoldEvents = async (eventId: string) => {
    try {
      if (!eventId) {
        return 0;
      }
      const resultList = await pb.collection("eventTickets").getList(1, 250, {
        filter: `event = '${eventId}'`,
      });
      soldEvents.value = resultList?.items || [];
    } catch (e) {}
  };

  return {
    addEditEventDialog,
    eventData,
    eventList,
    totalEvents,
    eventId,
    eventBookingsId,
    recurringEventId,
    recurringDetails,
    isExportLoading,
    selectedEvent,
    isConfirmEditEventDialog,
    isEventPending,
    confirmEditDialog,
    eventTicketsList,
    totalTickets,
    eventProducts,
    soldEvents,
    findSoldTicket,
    getEvents,
    handleEventEdit,
    handleExportEvents,
    handleExportEventTickets,
    checkEventType,
    fetchTickets,
    fetchTotalSoldEvents,
  };
});
