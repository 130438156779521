import { setupLayouts } from "virtual:generated-layouts";
import { createRouter, createWebHistory } from "vue-router";
import routes from "~pages";
import { pb } from "../utils/PocketBaseAdapter";
import { useUserRole } from "./permission";
import { isUserLoggedIn } from "./utils";

// store
const permission = useUserRole();

// Composable
const { getUserRolePermission, havePermission } = permission;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...setupLayouts(routes)],
});

const loginExemptPages = [
  "login",
  "forgot-password",
  "register",
  "confirm-verification",
  "confirm-password-reset",
  "account-setup",
];

// Define public pages that unauthenticated users can access
const publicPages = [
  "index",
  // "webshop",
  // "webshop-checkout",
  "faqs-user",
  "user-membership",
  ...loginExemptPages, // Combine with login exempt pages
];

router.beforeEach(async (to, from, next) => {
  try {
    const { isLoggedIn, userStatus } = await isUserLoggedIn(to);

    // Redirect suspended users to login
    if (isLoggedIn && userStatus === "Suspended") {
      pb.authStore.clear();
      localStorage.clear();
      return next({ name: "login" });
    }
    if (isLoggedIn) {
      // Prevent navigation to auth screens after login
      if (loginExemptPages.includes(String(to.name))) {
        return next({ name: "index" });
      }

      // Fetch user details and roles for permission check
      await getUserRolePermission();

      // Prevent access if the user doesn't have permission
      if (to.name !== "index" && !(await havePermission(to.name))) {
        return next({ name: "index" });
      }
    }
    // If the user is not logged in
    else {
      // Allow navigation to public pages without login
      if (!publicPages?.includes(String(to.name))) {
        // For protected pages, redirect to login and save the intended route
        if (to.name !== "all") {
          sessionStorage.setItem("userOnWayTo", JSON.stringify(to));
        }
        return next({ name: "login" });
      }
    }

    // Apply page-specific overflow settings
    document.documentElement.style.setProperty(
      "overflow-y",
      to.name === "pos" ? "hidden" : "auto"
    );

    // Clear docket print on specific pages
    if (to.name !== "pos") {
      localStorage.removeItem("printDocket");
    }

    // Proceed to the next route if all checks pass
    next();
  } catch (error) {
    console.error("Error during login check:", error);
    next(false); // Stop navigation in case of an error
  }
});

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

export default router;
